import { fetchFactory } from '../../../utils/fetch'
import { PAGE_SIZE } from '../../../utils/constants'

export const createUser = (values: AddUsersForm) => fetchFactory('/app-users', {
	method: 'POST',
	body: JSON.stringify(values)
})

export const getUsers = (query: string, page: number) => fetchFactory(`/app-users?query=${query}&page=${page}&size=${PAGE_SIZE}&disabled=true&sort=lastname%2CASC`)

export const handleUserStatus = (id: string, disable: boolean) => fetchFactory(`/users/${id}${disable ? '' : '/restore'}`, {
	method: disable ? 'DELETE' : 'POST'
})

export const sendGuestNotification = (id: string) => fetchFactory(`/app-users/${id}/notification`, {
	method: 'PUT'
})

export const updateUserRole = (id: string, values: UpdateUserRoleForm) => fetchFactory(`/app-users/${id}/role`, {
	method: 'PUT',
	body: JSON.stringify(values)
})
