import React from 'react'
import { CircularProgress, styled } from '@mui/material'

const StyledLoader = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '75vh'
})

const Loader = () => {
    return (
        <StyledLoader>
            <CircularProgress />
        </StyledLoader>
    )
}

export default Loader