import React, { useMemo } from 'react'
import { Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material'
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl'
import CircleIcon from '@mui/icons-material/Circle'
import ChartContainer from '../../../../components/charts/ChartContainer'
import TableHeader from '../../../../components/layout/TableHeader'
import { LABORATORIES } from '../../../../utils/constants'
import { GlobalDataSamples } from '../globalActivity'

const COLUMNS = ['laboratory', 'nbSamples', 'nbTypes', 'alert']
const LIMIT_PERCENT = 90

type SamplesProps = {
	data: GlobalDataSamples[]
	updatedDate: string
}

const Samples: React.FC<SamplesProps> = ({ data, updatedDate }) => {
	const intl = useIntl()

	const sortedData = useMemo(() => (
		[...data].sort((a, b) => ((LABORATORIES[a.laboratory as keyof typeof LABORATORIES] || {}).order || 10) -
			((LABORATORIES[b.laboratory as keyof typeof LABORATORIES] || {}).order || 10))
	), [data])

	return <ChartContainer
		title={<FormattedMessage id="activity.global.charts.samples.title" defaultMessage="Samples" description="Chart samples title" />}
		tooltip={intl.formatMessage({ id: 'activity.global.charts.samples.tooltip', defaultMessage: 'Samples tooltip', description: 'Chart samples tooltip' })}
		updatedDate={updatedDate}
		canExport={false}
	>
		<TableContainer sx={{ height: 400, width: '100%' }}>
			<Table stickyHeader aria-label="user table">
				<TableHeader>
					<TableRow>
						{COLUMNS.map((column) => (
							<TableCell key={column} align="center">
								<Typography variant="header1">
									<FormattedMessage id={`activity.global.charts.samples.headers.${column}`} defaultMessage={column} description="header column name" />
								</Typography>
							</TableCell>
						))}
					</TableRow>
				</TableHeader>
				<TableBody>
					{sortedData
						.map((data: GlobalDataSamples, index: number) => {
							const alert = data.typings * 100 / (data.samples || 1) >= LIMIT_PERCENT
							return <TableRow key={`samples-row-${index}`}>
								<TableCell align="center"><Typography variant="list1"><FormattedMessage
									id={`enums.laboratory.${data.laboratory}`}
									defaultMessage={data.laboratory}
									description="Laboratory name"
								/></Typography></TableCell>
								<TableCell align="center"><Typography variant="list1">
									<FormattedNumber value={data.samples} />
								</Typography></TableCell>
								<TableCell align="center"><Typography variant="list1"><FormattedNumber value={data.typings} /></Typography></TableCell>
								<TableCell align="center">
									<CircleIcon sx={{ color: alert ? '#34DD50' : '#FF0000', position: 'relative', top: '3px' }} />
								</TableCell>
							</TableRow>
						})}
				</TableBody>
			</Table>
		</TableContainer>
	</ChartContainer>
}

export default Samples
