import React, { ReactNode } from 'react'
import { CircularProgress, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { StyledPrimaryButton } from './button.styles'

type SubmitButtonProps = {
	label?: string,
	icon?: ReactNode
	loading?: boolean
	disabled?: boolean
}

const SubmitButton: React.FC<SubmitButtonProps> = ({ label, icon, loading, disabled }) => {
	return <StyledPrimaryButton variant="contained" type="submit" disabled={loading || disabled}>
		{icon}
		{loading && <CircularProgress />}
		{
			label && (
				<Typography variant="header2">
					<FormattedMessage id={label} defaultMessage={label} description="submit button" />
				</Typography>
			)
		}
	</StyledPrimaryButton>
}

export default SubmitButton
