import React, { ReactElement } from 'react'
import { Grid, styled, Typography } from '@mui/material'
import { colors } from '../../config/colors'
import { FormattedNumber } from 'react-intl'

type AlertTileProps = {
	count: number
	description: ReactElement
}

const AlertTileContainer = styled(Grid)({
	padding: '20px !important',
	backgroundColor: '#FFFFFF',
	border: '1px solid #F0F0F0',
	boxShadow: '3px 4px 7px rgba(0, 0, 0, 0.09)',
	borderRadius: 8,
	marginRight: 16
})

const AlertTileCount = styled(Typography)({
	color: colors.indicatorNumber,
	paddingRight: 20
})

const AlertTile: React.FC<AlertTileProps> = ({ count, description }) => {
	return <AlertTileContainer item xs={5} xl={3} container alignItems="center">
		<Grid item xs="auto"><AlertTileCount variant="indicator1"><FormattedNumber value={count} /></AlertTileCount></Grid>
		<Grid item xs><Typography variant="body1">{description}</Typography></Grid>
	</AlertTileContainer>
}

export default AlertTile
