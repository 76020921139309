import React, { useMemo } from 'react'
import { Grid, Typography } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import { FormattedMessage } from 'react-intl'
import { DATA_TYPE } from '../../utils/constants'
import { DateTime } from 'luxon'
import { FiltersFormValues } from '../form/filters/filters'

type EmptyStateProps = {
	filters?: FiltersFormValues
}

const EmptyState: React.FC<EmptyStateProps> = ({ filters }) => {
	const isLastCalculatedPeriod = useMemo(() => {
		if (!!filters) {
			switch (filters.periodType) {
				case DATA_TYPE.WEEK:
					return DateTime.fromISO(filters.period).weekNumber === DateTime.now().weekNumber
				case DATA_TYPE.MONTH:
					return DateTime.fromISO(filters.period).month === DateTime.now().month
				case DATA_TYPE.YEAR:
					return DateTime.fromISO(filters.period).year === DateTime.now().year
			}
		}
		return true
	}, [filters])

	return (
		<Grid container item xs={12} justifyContent="center" sx={{ mt: '10px' }}>
			<Grid item xs={12} sx={{ textAlign: 'center' }}>
				<InfoIcon sx={{ height: '100px', width: '100px', color: '#C7C7C7' }} />
			</Grid>
			<Grid item xs={10} sm={6} sx={{ textAlign: 'center' }}>
				<Typography variant="body2" sx={{ color: '#656565' }}>
					<FormattedMessage
						id={isLastCalculatedPeriod ? 'global.noProcessedFile' : 'global.noData'}
						defaultMessage="No data"
						description="No data placeholder"
						values={{
							p: (...chunks) => <p>{chunks}</p>,
							br: <br />
						}}
					/>
				</Typography>
			</Grid>
		</Grid>
	)
}

export default EmptyState
