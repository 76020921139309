import React from 'react'
import ReactDOM from 'react-dom/client'
import { styled, ThemeProvider } from '@mui/material'
import theme from './config/theme'
import Root from './containers/Root'
import { IntlProvider } from 'react-intl'
import messagesFr from './assets/lang/fr'
import { SnackbarProvider } from 'notistack'
import AppProvider from './modules/appData/AppProvider'
import { colors } from './config/colors'

const DEFAULT_LANGUAGE = 'fr'

const StyledSnackbarProvider = styled(SnackbarProvider)`
  &.SnackbarItem-variantSuccess {
    background-color: #92EDA1;
    color: ${colors.text};
  },
  &.SnackbarItem-variantError {
    background-color: #FFA0A0;
    color: ${colors.text};
  }
`

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
)
root.render(
	<React.StrictMode>
		<IntlProvider
			locale={DEFAULT_LANGUAGE}
			key={DEFAULT_LANGUAGE}
			messages={messagesFr}
			defaultFormats={{
				date: {
					datetime: {
						year: 'numeric',
						month: 'numeric',
						day: 'numeric',
						hour: 'numeric',
						minute: 'numeric'
					}
				}
			}}
			defaultLocale={DEFAULT_LANGUAGE}
		>
			<ThemeProvider theme={theme}>
				<AppProvider>
					<StyledSnackbarProvider anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }} maxSnack={3} hideIconVariant>
						<Root />
					</StyledSnackbarProvider>
				</AppProvider>
			</ThemeProvider>
		</IntlProvider>
	</React.StrictMode>
)
