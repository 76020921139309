import React, { useLayoutEffect, useMemo } from 'react'
import ChartContainer from '../../../../components/charts/ChartContainer'
import { FormattedMessage, useIntl } from 'react-intl'
import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import * as am5plugins_exporting from '@amcharts/amcharts5/plugins/exporting'
import { Exporting } from '@amcharts/amcharts5/plugins/exporting'
import { EXPORT_OPTIONS, LABORATORIES } from '../../../../utils/constants'
import { getFilenameWithDate } from '../../../../utils/dateUtils'
import { GlobalDataTypings } from '../globalActivity'

type TypesByLaboratoryProps = {
	data: GlobalDataTypings[],
	updatedDate: string
}

const CHART_NAME = 'chart-typesByLaboratory'
let exporting: Exporting | undefined = undefined

const TypesByLaboratory: React.FC<TypesByLaboratoryProps> = ({ data, updatedDate }) => {
	const intl = useIntl()

	// Sort by laboratory name
	// Translated laboratory name
	const formattedData = useMemo(() => (
		[...data].sort((a, b) => ((LABORATORIES[a.laboratory as keyof typeof LABORATORIES] || {}).order || 10) -
			((LABORATORIES[b.laboratory as keyof typeof LABORATORIES] || {}).order || 10))
			.map(dataValue => ({
				...dataValue,
				laboratory: intl.formatMessage({ id: `enums.laboratory.${dataValue.laboratory}`, defaultMessage: dataValue.laboratory, description: 'Laboratory name' })
			}))
	), [data, intl])

	useLayoutEffect(() => {
		const root = am5.Root.new(CHART_NAME)
		root.setThemes([am5themes_Animated.new(root)])
		root.numberFormatter.set('numberFormat', '#')

		exporting = am5plugins_exporting.Exporting.new(root, {
			dataSource: formattedData,
			...EXPORT_OPTIONS,
			filePrefix: getFilenameWithDate(intl.formatMessage({ id: 'global.charts.export.filename.typesByLaboratory', defaultMessage: CHART_NAME, description: 'Chart name' })),
			dataFields: {
				value: intl.formatMessage({ id: 'global.charts.export.headers.count', defaultMessage: 'Count', description: 'Count header' }),
				laboratory: intl.formatMessage({ id: 'global.charts.export.headers.laboratory', defaultMessage: 'Laboratory', description: 'Laboratory header' })
			},
			dataFieldsOrder: ['laboratory', 'value']

		})

		const chart = root.container.children.push(am5xy.XYChart.new(root, {
			panX: true,
			panY: true,
			wheelX: 'none',
			wheelY: 'none'
		}))

		// Axis
		const xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
			categoryField: 'laboratory',
			renderer: am5xy.AxisRendererX.new(root, {
				cellStartLocation: 0.1,
				cellEndLocation: 0.9
			}),
			tooltip: am5.Tooltip.new(root, {})
		}))
		xAxis.get('renderer').labels.template.setAll({
			location: 0.5,
			multiLocation: 0.5
		})
		xAxis.data.setAll(formattedData)

		const yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
			maxDeviation: 0.3,
			renderer: am5xy.AxisRendererY.new(root, {})
		}))

		// Series
		const series = chart.series.push(am5xy.ColumnSeries.new(root, {
			name: 'Types by laboratory',
			xAxis: xAxis,
			yAxis: yAxis,
			valueYField: 'value',
			categoryXField: 'laboratory',
			sequencedInterpolation: true,
			tooltip: am5.Tooltip.new(root, {
				labelText: '[bold]{laboratory} :[/]\n{valueY}'
			})
		}))
		series.data.setAll(formattedData)

		// Tooltips
		series.columns.template.setAll({
			tooltipText: '{valueY}',
			width: am5.percent(90),
			tooltipY: 0
		})

		// Colors
		series.columns.template.adapters.add('fill', (fill, target) =>
			chart?.get('colors')?.getIndex(series.columns.indexOf(target))
		)
		series.columns.template.adapters.add('stroke', (stroke, target) =>
			chart?.get('colors')?.getIndex(series.columns.indexOf(target))
		)

		series.appear(1000)
		chart.appear(1000, 100)

		return () => root.dispose()
	}, [formattedData, intl])

	return <ChartContainer
		title={<FormattedMessage id="activity.global.charts.typesByLaboratory.title" defaultMessage="Types by laboratory" description="Chart types by laboratory title" />}
		tooltip={intl.formatMessage({ id: 'activity.global.charts.typesByLaboratory.tooltip', defaultMessage: 'Types by laboratory tooltip', description: 'Chart types by laboratory tooltip' })}
		updatedDate={updatedDate}
		exportData={() => exporting?.download('xlsx')}
	>
		<div id={CHART_NAME} style={{ width: '100%', height: 400 }} />
	</ChartContainer>
}

export default TypesByLaboratory
