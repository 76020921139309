import React from 'react'
import { Button, styled, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'

type SecondaryButtonProps = {
	label: string
	onClick: () => void
}

const StyledSecondaryButton = styled(Button)(
	({ theme }) => ({
		padding: 12,
		borderRadius: 8,
		'& .MuiTypography-root': {
			color: theme.palette.primary.main
		}
	})
)

const SecondaryButton: React.FC<SecondaryButtonProps> = ({ label, onClick }) => {
	return <StyledSecondaryButton variant="text" sx={{ mr: '20px' }} onClick={onClick}>
		<Typography variant="header2">
			<FormattedMessage id={label} defaultMessage={label} description="secondary button" />
		</Typography>
	</StyledSecondaryButton>
}

export default SecondaryButton
