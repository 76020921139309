import React, { ReactElement } from 'react'
import { Grid, IconButton, Menu, MenuItem, styled, Typography } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import { colors } from '../../config/colors'
import Tooltip from '../layout/Tooltip'
import MenuIcon from '@mui/icons-material/Menu'
import { FormattedMessage } from 'react-intl'

type ChartContainerProps = {
	title: ReactElement
	tooltip: string
	updatedDate: string
	canExport?: boolean
	exportData?: () => void
	children: ReactElement
	xsWidth?: number
}

const StyledChartContainer = styled(Grid)({
	padding: '20px !important',
	backgroundColor: '#FFFFFF',
	border: '1px solid #F0F0F0',
	boxShadow: '3px 4px 7px rgba(0, 0, 0, 0.09)',
	borderRadius: 8
})

const ChartContainer: React.FC<ChartContainerProps> = (
	{
		title,
		tooltip,
		updatedDate,
		canExport = true,
		exportData = () => {
		},
		children,
		xsWidth = 6
	}
) => {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
	const open = Boolean(anchorEl)
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)
	const handleClose = () => setAnchorEl(null)

	return <>
		<Grid item container xs={xsWidth} sx={{ pr: '16px' }}>
			<StyledChartContainer item xs={12} container>
				<Grid item xs={12} container justifyContent="space-between">
					<Grid item xs={9}><Typography variant="h2">{title}</Typography></Grid>
					<Grid item xs={3} container alignItems="center" justifyContent="end" sx={{ mt: '-10px', maxHeight: '50px' }}>
						{canExport && <IconButton
							aria-label="close"
							onClick={handleClick}
							sx={{ mr: '12px' }}
						>
							<MenuIcon />
						</IconButton>}
						<Tooltip title={tooltip} placement="top-end" arrow>
							<InfoIcon sx={{ color: colors.text }} />
						</Tooltip>
					</Grid>
				</Grid>
				<Grid item xs={12} container justifyContent="space-between" sx={{ mt: '24px' }}>
					{children}
				</Grid>
				<Grid item xs={12} container justifyContent="end" alignItems="end" sx={{ mt: '16px' }}>
					<Typography variant="caption">
						<FormattedMessage
							id="global.charts.updatedDate"
							defaultMessage="Updated date"
							description="Updated date"
							values={{ date: updatedDate }}
						/>
					</Typography>
				</Grid>
			</StyledChartContainer>
		</Grid>
		<Menu
			id="export-menu"
			anchorEl={anchorEl}
			open={open}
			onClose={handleClose}
			MenuListProps={{ 'aria-labelledby': 'basic-button' }}
		>
			<MenuItem onClick={() => {
				handleClose()
				exportData()
			}}>
				<FormattedMessage
					id="global.charts.export.button"
					defaultMessage="Export data"
					description="export option"
				/>
			</MenuItem>
		</Menu>
	</>
}

export default ChartContainer
