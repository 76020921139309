import React from 'react'
import { DateTime } from 'luxon'
import { DatePicker } from '@mui/x-date-pickers'
import { FieldValues, useController } from 'react-hook-form'
import { StyledError, StyledField } from '../Form.styles'
import { DATA_TYPE } from '../../../utils/constants'
import { TextField } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import WeekDatePicker from './WeekDatePicker'
import { DatePickerProps } from './props/DatePickerProps'
import { getMondayOfCurrentWeek } from '../../../utils/dateUtils'

type PeriodDatePickerProps = {
	period: string
}

type FormPeriodDatePickerProps<TFieldValues extends FieldValues = FieldValues> =
	PeriodDatePickerProps
	& DatePickerProps<TFieldValues>

const now = DateTime.now()

const PeriodDatePicker = <TFieldValues extends FieldValues = FieldValues>({
	                                                                          label,
	                                                                          required,
	                                                                          disabled,
	                                                                          control,
	                                                                          name,
	                                                                          period
                                                                          }: FormPeriodDatePickerProps<TFieldValues>) => {
	const { field: { ref, ...fieldProps }, fieldState } = useController({ name, control })
	const value = fieldProps.value ? DateTime.fromISO(fieldProps.value) : now

	const commonProps = {
		...fieldProps,
		onChange: (newValue: DateTime | null) => fieldProps.onChange(newValue?.toISO()),
		disabled: disabled,
		disableFuture: true
	}
	const renderDatePickerByType = () => {
		switch (period) {
			case DATA_TYPE.MONTH:
				return (
					<DatePicker
						{...commonProps}
						openTo="year"
						views={['year', 'month']}
						value={value}
						renderInput={(params) => <TextField {...params} sx={{ width: '100%' }} />}
						label={label && `${label} ${required ? '*' : ''}`}
						shouldDisableMonth={(date) => date.year > now.year ||
							(date.month > now.month  && date.year === now.year) ||
							(date.month === now.month && getMondayOfCurrentWeek(now)?.month !== date.month)}
					/>
				)
			case DATA_TYPE.YEAR:
				return (
					<DatePicker
						{...commonProps}
						openTo="year"
						views={['year']}
						value={value}
						renderInput={(params) => <TextField {...params} sx={{ width: '100%' }} />}
						label={label && `${label} ${required ? '*' : ''}`}
						shouldDisableYear={(date) => date.year > now.year || (date.year === now.year && getMondayOfCurrentWeek(now)?.year !== date.year)}
					/>
				)
			case DATA_TYPE.WEEK:
			default:
				return (
					<WeekDatePicker
						label={label}
						control={control}
						{...commonProps}
					/>
				)
		}
	}
	return (
		<StyledField hasError={!!fieldState.error}>
			{renderDatePickerByType()}
			{!!fieldState.error &&
				<StyledError><FormattedMessage id={fieldState.error.message} defaultMessage="Date error"
				                               description="date error" /></StyledError>}
		</StyledField>
	)
}

export default PeriodDatePicker
