import React, { useEffect, useMemo, useState } from 'react'
import { Grid, Typography } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import AlertTile from '../../components/layout/AlertTile'
import Indicator from '../../components/layout/Indicator'
import IpvGenoTotal from './components/IpvGenoTotal'
import IpvGenoByBreed from './components/IpvGenoByBreed'
import Loader from '../../components/layout/Loader'
import { getDashboardAlertTiles, getDashboardGraphs } from './services/dashboardApi'
import { CURRENT_YEAR } from '../../utils/constants'
import EmptyState from '../../components/layout/EmptyState'

const Dashboard = () => {
	const intl = useIntl()
	const [loading, setLoading] = useState(true)
	const [alertTiles, setAlertTiles] = useState<DashboardAlertTiles | undefined>(undefined)
	const [graphs, setGraphs] = useState<DashboardGraphs[]>([])

	useEffect(() => {
		Promise
			.all([getDashboardAlertTiles(), getDashboardGraphs()])
			.then(result => {
				setAlertTiles(result[0])
				setGraphs(result[1])
			})
			.finally(() => setLoading(false))
	}, [])

	const alertTilesDate = useMemo(() => alertTiles && intl.formatDate(alertTiles.period), [alertTiles, intl])
	const graphCurrentYear = useMemo(() => graphs.find(g => new Date(g.year).getFullYear() === CURRENT_YEAR), [graphs])
	const graphPreviousYear = useMemo(() => graphs.find(g => new Date(g.year).getFullYear() === CURRENT_YEAR - 1), [graphs])
	const updatedDateCurrentYear = useMemo(() => graphCurrentYear ? intl.formatDate(graphCurrentYear.updateDate) : '', [graphCurrentYear, intl])

	return <>
		<Typography variant="h1"><FormattedMessage id="menu.dashboard" defaultMessage="Home" description="Home title" /></Typography>
		{loading ? <Loader /> : <>
			{(!alertTiles && !graphCurrentYear) && <EmptyState />}

			{!!alertTiles && <Grid item xs={12} container sx={{ mt: '32px' }}>
				<AlertTile
					count={alertTiles.nbIpvgenos}
					description={<FormattedMessage id="dashboard.alertTiles.ipvGeno" defaultMessage="IPVgeno" description="tile ipvGeno description" values={{ date: alertTilesDate, nbIpvGeno: alertTiles.nbIpvgenos }} />}
				/>
				<AlertTile
					count={alertTiles.nbErrors}
					description={<FormattedMessage id="dashboard.alertTiles.animals" defaultMessage="Animals" description="tile animals description" values={{ date: alertTilesDate, nbAnimals: alertTiles.nbErrors }} />}
				/>
			</Grid>}

			{graphCurrentYear && <>
				<Grid item xs={12} container sx={{ mt: '16px' }}>
					<Indicator
						title={<FormattedMessage id="dashboard.indicators.sample.title" defaultMessage="Samples" description="Indicator sample title" />}
						count={graphCurrentYear.totalSamples}
						description={<FormattedMessage id="dashboard.indicators.sample.description" defaultMessage="Samples description" description="Indicator sample description" values={{ nbSamples: graphCurrentYear.totalSamples }} />}
						tooltip={intl.formatMessage({ id: 'dashboard.indicators.sample.tooltip', defaultMessage: 'Samples tooltip', description: 'Indicator sample tooltip' })}
						updateDate={updatedDateCurrentYear}
						withEvolution={false}
					/>
					<Indicator
						title={<FormattedMessage id="dashboard.indicators.ipvGeno.title" defaultMessage="IPVgeno" description="Indicator ipvGeno title" values={{ year: CURRENT_YEAR }} />}
						count={graphCurrentYear.releasedIpvgenos}
						description={<FormattedMessage id="dashboard.indicators.ipvGeno.description" defaultMessage="IPVgeno description" description="Indicator ipvGeno description"
						                               values={{ year: CURRENT_YEAR, nbIpvGeno: graphCurrentYear.releasedIpvgenos }} />}
						tooltip={intl.formatMessage({ id: 'dashboard.indicators.ipvGeno.tooltip', defaultMessage: 'IPVgeno tooltip', description: 'Indicator IPVgeno tooltip' })}
						updateDate={updatedDateCurrentYear}
						withEvolution={false}
					/>
				</Grid>
				<Grid item xs={12} container sx={{ mt: '16px' }}>
					<IpvGenoTotal
						dataThisYear={graphCurrentYear.graphTotalIpvgenos.data}
						dataPreviousYear={graphPreviousYear ? graphPreviousYear.graphTotalIpvgenos.data : []}
						updatedDate={updatedDateCurrentYear}
					/>
					<IpvGenoByBreed
						data={graphCurrentYear.graphIpvgenosByBreed.data}
						updatedDate={updatedDateCurrentYear}
					/>
				</Grid>
			</>}
		</>}
	</>
}

export default Dashboard
