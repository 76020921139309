import React from 'react'
import { CircularProgress, styled } from '@mui/material'

const StyledLoader = styled(CircularProgress)({
	color: '#34DD50',
	position: 'absolute',
	top: 'calc(50% - 20px)',
	left: 'calc(50% - 20px)'
})

const GlobalLoader = () => {
	return <StyledLoader />
}

export default GlobalLoader
