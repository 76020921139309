import { fetchFactory } from '../../../utils/fetch'

export const updatePersonalData = (values: PersonalDataForm) => fetchFactory('/profile/personal', {
	method: 'PUT',
	body: JSON.stringify(values)
})

export const updatePasswordFromProfile = (values: UpdatePasswordProfileForm) => fetchFactory('/profile/password', {
	method: 'PUT',
	body: JSON.stringify({
		oldPassword: values.current,
		password: values.new,
		passwordRepeated: values.repeated
	})
})

