import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import { Close as CloseIcon } from '@mui/icons-material'
import React, { useCallback, useMemo } from 'react'
import FormSelect from '../../../components/form/FormSelect'
import { SNACK_VARIANT, USER_ROLES } from '../../../utils/constants'
import { useForm } from 'react-hook-form'
import { updateUserRole } from '../services/usersApi'
import { useSnackbar } from 'notistack'
import { SecondaryButton, SubmitButton } from '../../../components/button/button'

interface UpdateUserRoleDialogProps {
	user: User
	reloadTable: () => void
	onClose: () => void
}

const UpdateUserRoleDialog: React.FC<UpdateUserRoleDialogProps> = ({
	user,
	reloadTable,
	onClose
}: UpdateUserRoleDialogProps) => {
	const intl = useIntl()
	const { enqueueSnackbar } = useSnackbar()

	const updateUserRoleForm = useForm<UpdateUserRoleForm>({
		mode: 'onSubmit',
		defaultValues: {
			role: user.roles[0].code
		}
	})

	const submit = useCallback((values: UpdateUserRoleForm) => {
		return updateUserRole(user.id, values)
			.then(() => {
				onClose()
				reloadTable()
				enqueueSnackbar(intl.formatMessage({ id: `users.addSuccess`, defaultMessage: 'Users has been created', description: 'submit success' }), { variant: SNACK_VARIANT.SUCCESS })
			})
			.catch(e => {
				// Email error handler
				if (e && e.role) {
					updateUserRoleForm.setError('role', { type: e.role.id, message: `global.errors.${e.role.id}` })
					enqueueSnackbar(intl.formatMessage({ id: 'global.errors.formWithErrors', defaultMessage: 'Form with errors', description: 'form error' }), { variant: SNACK_VARIANT.ERROR })
				} else {
					enqueueSnackbar(intl.formatMessage({ id: 'global.errors.occured', defaultMessage: 'Error occured', description: 'error' }), { variant: SNACK_VARIANT.ERROR })
				}
			})
	}, [enqueueSnackbar, intl, onClose, reloadTable, updateUserRoleForm, user.id])

	const roleItems = useMemo(() => Object.values(USER_ROLES)
		.map(u => ({
			label: intl.formatMessage({ id: `enums.role.${u}`, defaultMessage: u, description: 'role option' }),
			value: u
		})), [intl])

	return <Dialog open={true} onClose={onClose} aria-labelledby="dialog-update-user" fullWidth>
		<form onSubmit={updateUserRoleForm.handleSubmit(submit)}>
			<DialogTitle sx={{ mb: '11px', pt: '20px', pX: '20px' }}>
				<Typography variant="h2"><FormattedMessage id="users.add" defaultMessage="Add user" description="User dialog title" /></Typography>
				{onClose && (
					<IconButton
						aria-label="close"
						onClick={onClose}
						sx={{
							position: 'absolute',
							right: 8,
							top: 12
						}}
					>
						<CloseIcon />
					</IconButton>
				)}
			</DialogTitle>
			<DialogContent sx={{ pt: '5px !important', pX: '20px' }}>
				<Grid item xs={12} container spacing={2}>
					<Grid item xs={12}>
						<FormSelect<UpdateUserRoleForm>
							id="role"
							name="role"
							label={intl.formatMessage({ id: 'global.user.role', defaultMessage: 'Role', description: 'role select' })}
							items={roleItems}
							required
							control={updateUserRoleForm.control}
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions sx={{ paddingX: '20px', pb: '20px' }}>
				<SecondaryButton
					label="global.buttons.cancel"
					onClick={onClose}
				/>
				<SubmitButton
					label="global.buttons.validate"
					loading={updateUserRoleForm.formState.isSubmitting}
				/>
			</DialogActions>
		</form>
	</Dialog>

}

export default UpdateUserRoleDialog