import { ReactNode } from 'react'
import { Control, FieldPath, FieldValues, useController } from 'react-hook-form'
import { TextField } from '@mui/material'
import { StyledField, StyledError } from './Form.styles'
import { FormattedMessage } from 'react-intl'

type FormInputProps<TFieldValues extends FieldValues = FieldValues> = {
	id: string
	type?: string
	label?: string
	placeholder?: string
	required?: boolean
	disabled?: boolean
	endAdornment?: ReactNode
	control: Control<TFieldValues>
	name: FieldPath<TFieldValues>
}

const FormInput = <TFieldValues extends FieldValues = FieldValues>({
	                                                                   id,
	                                                                   type = 'text',
	                                                                   label,
	                                                                   placeholder,
	                                                                   required,
	                                                                   disabled,
	                                                                   endAdornment,
	                                                                   control,
	                                                                   name
                                                                   }: FormInputProps<TFieldValues>) => {
	const { field, fieldState } = useController({ name, control })

	return (
		<StyledField hasError={!!fieldState.error}>
			<TextField
				id={id}
				type={type}
				label={label && `${label} ${required ? '*' : ''}`}
				placeholder={placeholder}
				disabled={disabled}
				fullWidth
				inputRef={field.ref}
				error={!!fieldState.error}
				InputProps={{ endAdornment: endAdornment || <></> }}
				autoComplete="off"
				{...field}
			/>
			{!!fieldState.error && <StyledError><FormattedMessage id={fieldState.error.message} defaultMessage="Input error" description="Input error" /></StyledError>}
		</StyledField>
	)
}

export default FormInput
