import React, { useLayoutEffect } from 'react'
import ChartContainer from '../../../../components/charts/ChartContainer'
import { FormattedMessage, useIntl } from 'react-intl'
import * as am5 from '@amcharts/amcharts5'
import * as am5percent from '@amcharts/amcharts5/percent'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import { LaboActivityChipType } from '../activityByLaboratory'
import { Exporting } from '@amcharts/amcharts5/plugins/exporting'
import * as am5plugins_exporting from '@amcharts/amcharts5/plugins/exporting'
import { EXPORT_OPTIONS } from '../../../../utils/constants'
import { getFilenameWithDate } from '../../../../utils/dateUtils'

type DistributionByMicrochipTypeProps = {
	data: LaboActivityChipType[]
	laboratory: string
	updateDate: string
}

const CHART_NAME = 'chart-distributionByMicrochipType'
let exporting: Exporting | undefined = undefined

const DistributionByMicrochipType: React.FC<DistributionByMicrochipTypeProps> = ({ data, laboratory, updateDate }) => {
	const intl = useIntl()

	useLayoutEffect(() => {
		const root = am5.Root.new(CHART_NAME)
		root.setThemes([am5themes_Animated.new(root)])
		root.numberFormatter.set('numberFormat', '#')

		exporting = am5plugins_exporting.Exporting.new(root, {
			dataSource: data,
			...EXPORT_OPTIONS,
			filePrefix: getFilenameWithDate(intl.formatMessage({ id: 'global.charts.export.filename.byMicrochipType', defaultMessage: CHART_NAME, description: 'Chart name' }, { laboratory })),
			dataFields: {
				value: intl.formatMessage({ id: 'global.charts.export.headers.count', defaultMessage: 'Count', description: 'Count header' }),
				microchip: intl.formatMessage({ id: 'global.charts.export.headers.microchip', defaultMessage: 'Microchip', description: 'Microchip header' })
			},
			dataFieldsOrder: ['microchip', 'value']
		})

		const chart = root.container.children.push(am5percent.PieChart.new(root, {
			layout: root.verticalLayout,
			innerRadius: am5.percent(50),
			reverseChildren: true
		}))

		// Series
		const series = chart.series.push(am5percent.PieSeries.new(root, {
			valueField: 'value',
			categoryField: 'microchip',
			alignLabels: false,
			legendLabelText: '{category}',
			legendValueText: ''
		}))
		series.labels.template.setAll({
			textType: 'circular',
			centerX: 0,
			centerY: 0,
			text: '{value}'
		})
		series.data.setAll(data)

		// Tooltips
		series.slices.template.set('tooltipText', '[bold]{category}:[/] {value}')

		// Legend
		const legend = chart.children.push(
			am5.Legend.new(root, {
				centerX: am5.p50,
				x: am5.p50,
				y: 0
			})
		)
		legend.data.setAll(series.dataItems)

		series.appear(1000, 100)

		return () => root.dispose()
	}, [data, laboratory, intl])

	return <ChartContainer
		title={<FormattedMessage id="activity.byLaboratory.charts.byMicrochipType.title"
		                         defaultMessage="By microchip type" description="Chart by microchip type title" />}
		tooltip={intl.formatMessage({
			id: 'activity.byLaboratory.charts.byMicrochipType.tooltip',
			defaultMessage: 'By microchip type tooltip',
			description: 'Chart by microchip type tooltip'
		})}
		updatedDate={updateDate}
		exportData={() => exporting?.download('xlsx')}
		xsWidth={8}
	>
		<div id="chart-distributionByMicrochipType" style={{ width: '100%', height: 400 }}/>
	</ChartContainer>
}

export default DistributionByMicrochipType
