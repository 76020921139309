import React, { useEffect, useState } from 'react'
import { AppContext } from './AppContext'
import { fetchFactory, fetchUser, refresh as refreshApi } from '../../utils/fetch'
import { getUserToken, storeUserToken } from '../../utils/localStorage'
import GlobalLoader from '../../components/layout/GlobalLoader'

type AppProviderProps = {
	children: React.ReactNode
}

const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
	const [user, setUser] = useState<User | undefined>()
	const [token, setToken] = useState<string | undefined>()
	const [connected, setConnected] = useState<boolean>(false)
	const [loading, setLoading] = useState<boolean>(true)
	const [taskInProgress, setTaskInProgress] = useState<boolean>(false)

	// App load
	useEffect(() => {
		const token = getUserToken()
		if (token) {
			fetchUser(token)
				.then(handleConnectedUser)
				.finally(() => setLoading(false))
		} else {
			setLoading(false)
		}
	}, [])

	// Refresh
	useEffect(() => {
		refresh()

		const refreshInterval = setInterval(() => {
			refresh()
		}, 1700 * 1000)

		return () => {
			if (refreshInterval) {
				clearInterval(refreshInterval)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const listenTaskExecution = () => {
		const interval = setInterval(() => {
			fetchFactory('/run-task/pending')
				.catch(() => {
					setTaskInProgress(false)
					clearInterval(interval)
				})
		}, 300000)
	}

	// Check if task in progress
	useEffect(() => {
		fetchFactory('/run-task/pending')
			.then(() => {
				setTaskInProgress(true)
				listenTaskExecution()
			})
			.catch(() => {
				// Do nothing
			})
	}, [])

	const handleTaskInProgress = () => {
		setTaskInProgress(true)
		listenTaskExecution()
	}

	async function refresh() {
		if (user && token) {
			refreshApi(getUserToken() || '').then((json) => {
				if (json) {
					storeUserToken(json.token)
				}
			})
		}
	}

	const handleConnectedUser = (user: User | undefined) => {
		setUser(user)
		setConnected(!!user)
	}

	if (loading) {
		return <GlobalLoader />
	}

	return <AppContext.Provider value={{
		user,
		token,
		connected,
		taskInProgress,
		updateUser: setUser,
		handleConnectedUser,
		setToken,
		handleTaskInProgress
	}}>
		{children}
	</AppContext.Provider>
}

export default AppProvider
