import React from 'react'
import ChartContainer from '../../../../components/charts/ChartContainer'
import { FormattedMessage, useIntl } from 'react-intl'
import { Grid, Stack } from '@mui/material'
import EllipseIndicator from '../../../../components/layout/ElipseIndicator'
import { LaboActivityChipVersion } from '../activityByLaboratory'

type DistributionByMicrochipVersionProps = {
    data : LaboActivityChipVersion[],
    updateDate: string
}

const DistributionByMicrochipVersion: React.FC<DistributionByMicrochipVersionProps> = ({ data, updateDate }) => {
	const intl = useIntl()

    return <ChartContainer
        title={<FormattedMessage id="activity.byLaboratory.charts.byMicrochipVersion.title"
                                 defaultMessage="By microchip version"
                                 description="Chart by microchip version title" />}
        tooltip={intl.formatMessage({
            id: 'activity.byLaboratory.charts.byMicrochipVersion.tooltip',
            defaultMessage: 'By microchip version tooltip',
            description: 'Chart by microchip version tooltip'
        })}
        updatedDate={updateDate}
        canExport={false}
        xsWidth={4}
    >
        <Grid item container justifyContent="center" spacing={4}>
            <Grid item>
                <Stack spacing={4}>
                    <EllipseIndicator
                        smallSize
                        color="#873191"
                        label={<FormattedMessage
                            id="activity.byLaboratory.charts.byMicrochipVersion.label"
                            defaultMessage="Chip version label"
                            description="Chip version label"
                            values={{ version: data.length > 0 ? data[0].label : "" }}
                        />}
                        count={data.length > 0 ? data[0].count : 0}
                        important={data.length > 0 && data[0].count > 0}
                    />
                    <EllipseIndicator
                        smallSize
                        color="#3E84D1"
                        label={<FormattedMessage
                            id="activity.byLaboratory.charts.byMicrochipVersion.label"
                            defaultMessage="Chip version label"
                            description="Chip version label"
                            values={{ version: data.length > 1 ? data[1].label : "" }}
                        />}
                        count={data.length > 1 ? data[1].count : 0}
                        important={data.length > 1 && data[1].count > 0}
                    />
                </Stack>
            </Grid>
            <Grid item sx={{ mt: 'auto', mb: 'auto' }}>
                <EllipseIndicator
                    color="#168328"
                    label={<FormattedMessage
                        id="activity.byLaboratory.charts.byMicrochipVersion.label"
                        defaultMessage="Chip version label"
                        description="Chip version label"
                        values={{ version: data.length > 2 ? data[2].label : "" }}
                    />}
                    count={data.length > 2 ? data[2].count : 0}
                />
            </Grid>
        </Grid>
    </ChartContainer>
}

export default DistributionByMicrochipVersion
