import React, { ReactNode } from 'react'
import { CircularProgress, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { StyledPrimaryButton } from './button.styles'

type PrimaryButtonProps = {
	label: string
	icon?: ReactNode
	loading?: boolean
	disabled?: boolean
	onClick: () => void
}

const PrimaryButton: React.FC<PrimaryButtonProps> = ({ label, icon, loading = false, disabled = false, onClick }) => {
	return <StyledPrimaryButton variant="contained" disabled={disabled || loading} onClick={onClick}>
		{icon}
		{loading && <CircularProgress />}
		<Typography variant="header2" sx={{ ml: icon ? '10px' : '0px'}}>
			<FormattedMessage id={label} defaultMessage={label} description="primary button" />
		</Typography>
	</StyledPrimaryButton>
}

export default PrimaryButton
