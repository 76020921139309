import React from 'react'
import ChartContainer from '../../../../components/charts/ChartContainer'
import { FormattedMessage, useIntl } from 'react-intl'
import { Grid } from '@mui/material'
import EllipseIndicator from '../../../../components/layout/ElipseIndicator'
import { ClientActivitySex } from '../activityByClient'

type TypesBySexeProps = {
	data: ClientActivitySex[],
	updateDate: string
}

const TypesBySexe: React.FC<TypesBySexeProps> = ({ data, updateDate }) => {
	const intl = useIntl()

	return <ChartContainer
		title={<FormattedMessage id="activity.byClient.charts.typesBySexe.title" defaultMessage="Types by sex"
		                         description="Chart types by sex title" />}
		tooltip={intl.formatMessage({
			id: 'activity.byClient.charts.typesBySexe.tooltip',
			defaultMessage: 'Types by sex',
			description: 'Chart types by sex tooltip'
		})}
		updatedDate={updateDate}
		canExport={false}
	>
		<Grid item container justifyContent="center" spacing={4}>
			<Grid item sx={{ mt: 'auto', mb: 'auto' }}>
				<EllipseIndicator
					smallSize
					color="#873191"
					label={<FormattedMessage
						id={`enums.sex.${data[0].label}`}
						defaultMessage="Typings by sex label"
						description="Typings by sex label"
					/>}
					count={data[0].count}
				/>
			</Grid>
			<Grid item sx={{ mt: 'auto', mb: 'auto' }}>
				<EllipseIndicator
					color="#168328"
					label={<FormattedMessage
						id={`enums.sex.${data[1].label}`}
						defaultMessage="Typings by sex label"
						description="Typings by sex label"
					/>}
					count={data[1].count}
				/>
			</Grid>
			<Grid item sx={{ mt: 'auto', mb: 'auto' }}>
				<EllipseIndicator
					smallSize
					color="#3E84D1"
					label={<FormattedMessage
						id={`enums.sex.${data[2].label}`}
						defaultMessage="Typings by sex label"
						description="Typings by sex label"
					/>}
					count={data[2].count}
				/>
			</Grid>
		</Grid>
	</ChartContainer>
}

export default TypesBySexe
