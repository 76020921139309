import React, { useLayoutEffect, useMemo } from 'react'
import ChartContainer from '../../../../components/charts/ChartContainer'
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl'
import { EXPORT_OPTIONS } from '../../../../utils/constants'
import { Grid, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material'
import TableHeader from '../../../../components/layout/TableHeader'
import * as am5 from '@amcharts/amcharts5'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import * as am5xy from '@amcharts/amcharts5/xy'
import { ClientActivityBreed } from '../activityByClient'
import * as am5plugins_exporting from '@amcharts/amcharts5/plugins/exporting'
import { Exporting } from '@amcharts/amcharts5/plugins/exporting'
import { getFilenameWithDate } from '../../../../utils/dateUtils'

type TypesByBreedProps = {
	data: ClientActivityBreed[]
	client: string
	updateDate: string
}

const COLUMNS = ['race', 'type']
const CHART_NAME = 'chart-typesByBreed'
let exporting: Exporting | undefined = undefined

const TypesByBreed: React.FC<TypesByBreedProps> = ({ data, client, updateDate }) => {
	const intl = useIntl()

	const formattedData = useMemo(() => (data
		.filter(d => d.value > 0)
			.map(d => ({
				name: intl.formatMessage({
					id: 'enums.breed.RACE_PREFIX',
					description: 'Breed label',
					defaultMessage: 'Breed label'
				}, { breed: d.name }),
				value: d.value
			}))
	), [data, intl])

	useLayoutEffect(() => {
		const root = am5.Root.new(CHART_NAME)
		root.setThemes([am5themes_Animated.new(root)])
		root.numberFormatter.set('numberFormat', '#')

		exporting = am5plugins_exporting.Exporting.new(root, {
			dataSource: formattedData,
			...EXPORT_OPTIONS,
			filePrefix: getFilenameWithDate(intl.formatMessage({ id: 'global.charts.export.filename.typesByBreed', defaultMessage: CHART_NAME, description: 'Chart name' }, { client })),
			dataFields: {
				value: intl.formatMessage({ id: 'global.charts.export.headers.count', defaultMessage: 'Count', description: 'Count header' }),
				name: intl.formatMessage({ id: 'global.charts.export.headers.breed', defaultMessage: 'Laboratory', description: 'Laboratory header' })
			},
			dataFieldsOrder: ['name', 'value']
		})

		const chart = root.container.children.push(am5xy.XYChart.new(root, {
			panX: false,
			panY: false,
			wheelX: 'none',
			wheelY: 'none',
			layout: root.verticalLayout
		}))

		// Axis
		const yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(root, {
			categoryField: 'name',
			renderer: am5xy.AxisRendererY.new(root, {
				inversed: true,
				cellStartLocation: 0.1,
				cellEndLocation: 0.9
			})
		}))
		yAxis.data.setAll(formattedData)
		const xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, {
			renderer: am5xy.AxisRendererX.new(root, {}),
			min: 0
		}))

		// Series
		const series = chart.series.push(am5xy.ColumnSeries.new(root, {
			name: 'Types by breed',
			xAxis: xAxis,
			yAxis: yAxis,
			valueXField: 'value',
			sequencedInterpolation: true,
			categoryYField: 'name'
		}))
		series.data.setAll(formattedData)

		// Colors
		series.columns.template.adapters.add('fill', (fill, target) =>
			chart?.get('colors')?.getIndex(series.columns.indexOf(target))
		)
		series.columns.template.adapters.add('stroke', (stroke, target) =>
			chart?.get('colors')?.getIndex(series.columns.indexOf(target))
		)

		const cursor = chart.set('cursor', am5xy.XYCursor.new(root, {
			behavior: 'zoomY'
		}))
		cursor.lineY.set('forceHidden', true)
		cursor.lineX.set('forceHidden', true)

		chart.appear(1000, 100)

		return () => root.dispose()
	}, [formattedData, client, intl])

	return <ChartContainer
		title={<FormattedMessage id="activity.byClient.charts.typesByBreed.title" defaultMessage="Types by breed"
		                         description="Chart types by breed title" />}
		tooltip={intl.formatMessage({
			id: 'activity.byClient.charts.typesByBreed.tooltip',
			defaultMessage: 'Types by breed type',
			description: 'Chart types by breed tooltip'
		})}
		updatedDate={updateDate}
		exportData={() => exporting?.download('xlsx')}
		xsWidth={12}
	>
		<>
			<Grid item xs={8}>
				<div id="chart-typesByBreed" style={{ width: '100%', height: 400 }} />
			</Grid>
			<Grid item xs={4}>
				<TableContainer sx={{ height: 400, width: '100%' }}>
					<Table stickyHeader aria-label="user table">
						<TableHeader>
							<TableRow>
								{COLUMNS.map((column) => (
									<TableCell key={column} align="center">
										<Typography variant="header1">
											<FormattedMessage
												id={`activity.byClient.charts.typesByBreed.headers.${column}`}
												defaultMessage={column} description="header column name" />
										</Typography>
									</TableCell>
								))}
							</TableRow>
						</TableHeader>
						<TableBody>
							{data
								.map((item: any, index: number) => {
									return <TableRow key={`breeds-row-${index}`}>
										<TableCell align="center"><Typography variant="list1">
											<FormattedMessage
												id="enums.breed.RACE_PREFIX"
												description="Breed label"
												defaultMessage="Breed label"
												values={{ breed: item.name }}
											/>
										</Typography></TableCell>
										<TableCell align="center"><Typography variant="list1">
											<FormattedNumber value={item.value} />
										</Typography></TableCell>
									</TableRow>
								})}
						</TableBody>
					</Table>
				</TableContainer>
			</Grid>
		</>
	</ChartContainer>
}

export default TypesByBreed
