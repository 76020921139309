import React, { useMemo } from 'react'
import { DatePicker, PickersDay, PickersDayProps } from '@mui/x-date-pickers'
import { DateTime } from 'luxon'
import { styled, TextField } from '@mui/material'
import { FieldValues, useController } from 'react-hook-form'
import { DatePickerProps } from './props/DatePickerProps'
import { useIntl } from 'react-intl'
import { getMondayOfCurrentWeek } from '../../../utils/dateUtils'

interface CustomPickerDayProps extends PickersDayProps<DateTime> {
	dayIsBetween: boolean
	isFirstDay: boolean
	isLastDay: boolean
}

const shouldForwardProps = ['dayIsBetween', 'isFirstDay', 'isLastDay']

const StyledPickersDay = styled(PickersDay, {
	shouldForwardProp: (prop: string) => {
		return !shouldForwardProps.includes(prop)
	}
})<CustomPickerDayProps>(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
	...(dayIsBetween && {
		borderRadius: 0,
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.common.white,
		'&:hover, &:focus': {
			backgroundColor: theme.palette.primary.dark
		}
	}),
	...(isFirstDay && {
		borderTopLeftRadius: '50%',
		borderBottomLeftRadius: '50%'
	}),
	...(isLastDay && {
		borderTopRightRadius: '50%',
		borderBottomRightRadius: '50%'
	})
})) as React.ComponentType<CustomPickerDayProps>

const WeekDatePicker = <TFieldValues extends FieldValues = FieldValues>({
	                                                                        label,
	                                                                        required,
	                                                                        disabled,
	                                                                        control,
	                                                                        name
                                                                        }: DatePickerProps<TFieldValues>
) => {
	const intl = useIntl()
	const { field } = useController({ name, control })
	const value = field.value ? DateTime.fromISO(field.value) : DateTime.now()

	const start = useMemo(() => (value ? value : DateTime.now()).startOf('week'), [value])
	const end = useMemo(() => (value ? value : DateTime.now()).endOf('week'), [value])

	const renderWeekPickerDay = (
		date: DateTime,
		selectedDates: Array<DateTime | null>,
		pickersDayProps: PickersDayProps<DateTime>
	) => {
		const afterStart = date.diff(start, 'day').days >= 0 && date.diff(start, 'day').days <= 7
		const beforeEnd = date.diff(end, 'day').days >= -7 && date.diff(end, 'day').days <= 0
		const dayIsBetween = afterStart && beforeEnd
		const isFirstDay = date.hasSame(start, 'day')
		const isLastDay = date.hasSame(end, 'day')
		return (
			<StyledPickersDay
				{...pickersDayProps}
				disableMargin
				dayIsBetween={dayIsBetween}
				isFirstDay={isFirstDay}
				isLastDay={isLastDay}
			/>
		)
	}

	return (
		<DatePicker
			{...field}
			views={['day']}
			onChange={newValue => field.onChange(getMondayOfCurrentWeek(newValue)?.toISO())}
			value={value}
			renderInput={(params) => <TextField {...params} sx={{ width: '100%' }} />}
			label={label && `${label} ${required ? '*' : ''}`}
			disabled={disabled}
			disableFuture
			renderDay={renderWeekPickerDay}
			inputFormat={intl.formatMessage({
				id: 'activity.filters.period.week',
				defaultMessage: 'week',
				description: 'week selection'
			})}
			shouldDisableDate={(date) => date.month === DateTime.now().month && getMondayOfCurrentWeek(date)?.month !== date.month}
		/>
	)
}

export default WeekDatePicker
