import React, { useContext } from 'react'
import { StyledLoginContainer, StyledLoginForm, StyledLoginImg, StyledLoginLink, StyledLoginTitle } from './Login.styles'
import logo from '../../assets/img/logo.png'
import { Grid, Typography } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import { Navigate, useNavigate } from 'react-router'
import { APP_ROUTES } from '../../utils/constants'
import FormInput from '../../components/form/FormInput'
import { FieldErrors, Resolver, useForm } from 'react-hook-form'
import { isValidEmail } from '../../utils/formUtils'
import { fetchUser, login } from '../../utils/fetch'
import { SubmitButton } from '../../components/button/button'
import { AppModel } from '../appData/appModel'
import { AppContext } from '../appData/AppContext'
import { storeUserToken } from '../../utils/localStorage'
import FormPassword from '../../components/form/FormPassword'

const resolver: Resolver<LoginForm> = async (values: LoginForm) => {
	const errors: FieldErrors<LoginForm> = {}

	if (!values.email) {
		errors.email = { type: 'required', message: 'global.errors.required' }
	} else if (!isValidEmail(values.email)) {
		errors.email = { type: 'format', message: 'global.errors.format' }
	}

	if (!values.password) {
		errors.password = { type: 'required', message: 'global.errors.required' }
	}
	return { values, errors }
}

const Login = () => {
	const navigate = useNavigate()
	const intl = useIntl()
	const { connected, handleConnectedUser, setToken } = useContext<AppModel>(AppContext)
	const loginForm = useForm<LoginForm>({
		mode: 'onSubmit',
		defaultValues: {
			email: '',
			password: ''
		},
		resolver
	})

	const handleSubmit = (values: LoginForm) => {
		return (new Promise((resolve, reject) => login(values)
			.then(res => {
				if (res) {
					storeUserToken(res.token)
					setToken(res.token)
					resolve(res.token)
				} else {
					reject()
				}
			})
			.catch(() => reject())
		) as Promise<string>)
			.then((token: string) => fetchUser(token))
			.then(user => {
				handleConnectedUser(user)
				navigate(APP_ROUTES.HOME)
			})
			.catch(() => loginForm.setError('password', { type: 'requestError', message: 'login.error' }))
	}

	if (connected) {
		return <Navigate to={APP_ROUTES.HOME} />
	}

	return <StyledLoginContainer onSubmit={loginForm.handleSubmit(handleSubmit)}>
		<Grid item xs={12} height="100%" container>

			<StyledLoginImg item xs={6} />
			<StyledLoginForm item xs={6} container>

				<Grid item xs={12} alignItems="flex-start" sx={{ maxHeight: '70vh', overflow: 'auto' }}>
					<Grid item xs={12} textAlign="center" sx={{ mb: '6vh' }}><img src={logo} alt={logo} height={62} /></Grid>
					<Grid item xs={12} textAlign="center"><StyledLoginTitle><FormattedMessage id="login.title" defaultMessage="Welcome" description="login title" /></StyledLoginTitle></Grid>
					<Grid item xs={12} textAlign="center" sx={{ mb: '32px' }}><Typography variant="body2"><FormattedMessage id="login.description" defaultMessage="Welcome description" description="login description" /></Typography></Grid>
					<Grid item xs={12} container justifyContent="center">
						<Grid item xs={5}>
							<FormInput<LoginForm>
								id="email"
								name="email"
								label={intl.formatMessage({ id: 'login.form.email', defaultMessage: 'Email', description: 'email input' })}
								required
								control={loginForm.control}
							/>
						</Grid>
					</Grid>
					<Grid item xs={12} container justifyContent="center">
						<Grid item xs={5}>
							<FormPassword<LoginForm>
								id="password"
								name="password"
								label={intl.formatMessage({ id: 'login.form.password', defaultMessage: 'Password', description: 'password input' })}
								control={loginForm.control}
							/>
						</Grid>
					</Grid>
					<Grid item xs={12} textAlign="center" sx={{ mb: '12px' }}>
						<SubmitButton
							label="global.buttons.connect"
							loading={loginForm.formState.isSubmitting}
						/>
					</Grid>
					<Grid item xs={12} textAlign="center">
						<StyledLoginLink to="/forgotten-password"><FormattedMessage id="login.link" defaultMessage="Forgotten password" description="forgotten password link" /></StyledLoginLink>
					</Grid>
				</Grid>
			</StyledLoginForm>
		</Grid>
	</StyledLoginContainer>
}

export default Login
