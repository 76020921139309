import { VariantType } from 'notistack'

export const APPBAR_HEIGHT = 64
export const DRAWER_WIDTH = 250
export const PAGE_SIZE = 25
export const MOCK_UPDATED_DATE = '25/04/2022'
export const CURRENT_YEAR = new Date().getFullYear()
export const EXPORT_OPTIONS = {
	pngOptions: { disabled: true },
	jpgOptions: { disabled: true },
	pdfOptions: { disabled: true },
	pdfdataOptions: { disabled: true },
	csvOptions: { disabled: true },
	jsonOptions: { disabled: true },
	htmlOptions: { disabled: true },
	printOptions: { disabled: true }
}

export const APP_ROUTES = {
	LOGIN: '/login',
	FORGOTTEN_PASSWORD: '/forgotten-password',
	RESET_PASSWORD: '/reset-password',
	HOME: '/',
	GLOBAL_ACTIVITY: '/activity/global',
	ACTIVITY_BY_LABORATORY: '/activity/laboratory',
	ACTIVITY_BY_CLIENT: '/activity/client',
	USERS: '/users',
	PROFILE: '/my-profile'
}

type Snack = {
	[key: string]: VariantType
}
export const SNACK_VARIANT: Snack = {
	SUCCESS: 'success',
	ERROR: 'error'
}

export const LOCAL_STORAGE_ITEM = {
	TOKEN: 'token'
}

export const USER_ROLES = {
	ADMIN: 'ADMIN',
	MEMBER: 'MEMBER'
}

export const USER_PREFERENCES = {
	IS_GUEST: 'IS_GUEST'
}

export enum DATA_TYPE {
	WEEK = 'WEEK',
	MONTH = 'MONTH',
	YEAR = 'YEAR'
}

export const DEFAULT_VALUE_LABO = {
	name: 'Labogena',
	code: 'Labogena'
}
export const DEFAULT_VALUE_CLIENT = {
	name: 'S917',
	code: 'S917'
}

export const REFERENTIAL_TYPE = {
	LABORATORY: 'LABORATORY',
	CLIENT: 'CLIENT'
}

export const API_CALL_ACTIVITY_KEY = {
	GLOBAL: 'global',
	LABORATORY: 'laboratory',
	CLIENT: 'client'
}

export const BREEDS = {
	PRIM_HOLSTEIN: { code: 'PRIM_HOLSTEIN', color: '#168328'},
	NORMANDE: { code: 'NORMANDE', color: '#BFD6F3'},
	MONTBELIARDE: { code: 'MONTBELIARDE', color: '#B141BE'},
	INTERNATIONAL: { code: 'INTERNATIONAL', color: '#92EDA1'},
	MASSIFS: { code: 'MASSIFS', color: '#133864'},
	BREASTFEEDING_WITHOUT_IPVGENO: { code: 'BREASTFEEDING_WITHOUT_IPVGENO', color: '#6A2772'},
	BREASTFEEDING: { code: 'BREASTFEEDING', color: '#34DD50'},
	MIXED: { code: 'MIXED', color: '#3E84D1'}
}

export const LABORATORIES = {
	LABOGENA: { name: 'Labogena', order: 1 },
	GDIPL: { name: 'GD IPL', order: 2 },
	AGRANIS: { name: 'AGRANIS', order: 3 },
	AVEYRON_LABO: { name: 'AVEYRON-LABO', order: 4 },
	ETRANGERS: { name: 'Etrangers', order: 5 }
}

export enum GLOBAL_CONTENT_TYPE {
	IPVGENO = 'ipvgenos',
	ECHANTILLON = 'echantillons'
}

export enum FILE_TYPE {
	CLIENT = 'client',
	LABO = 'labo'
}

export enum CLIENT_CONTENT_TYPE {
	IPVGENO = 'ipvgenos',
	ANIMAUX = 'animaux'
}

export enum LABO_CONTENT_TYPE {
	TYPAGE = 'typages',
	TYPAGE_ERREUR = 'typages-erreur',
	ECHANTILLON_RETARD = 'echantillons-retard'
}

export type CLIENT_LABO_CONTENT_ASSOCIATION = {
	[FILE_TYPE.CLIENT]: CLIENT_CONTENT_TYPE,
	[FILE_TYPE.LABO]: LABO_CONTENT_TYPE
}