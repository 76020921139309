import React, { useEffect, useState } from 'react'
import { StyledErrorToken, StyledLoginContainer, StyledLoginForm, StyledLoginImg, StyledLoginTitle } from './Login.styles'
import logo from '../../assets/img/logo.png'
import { Grid, Typography } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import { FieldErrors, Resolver, useForm } from 'react-hook-form'
import { APP_ROUTES, SNACK_VARIANT } from '../../utils/constants'
import { useSnackbar } from 'notistack'
import { isValidPassword } from '../../utils/formUtils'
import { SubmitButton } from '../../components/button/button'
import { checkTokenValidity, updatePassword } from './services/loginApi'
import { useSearchParams } from 'react-router-dom'
import { useNavigate } from 'react-router'
import FormPassword from '../../components/form/FormPassword'

type UpdatePasswordProps = {
	initPassword?: boolean
}

const resolver: Resolver<UpdatePasswordForm> = async (values: UpdatePasswordForm) => {
	const errors: FieldErrors<UpdatePasswordForm> = {}

	if (!values.password) {
		errors.password = { type: 'required', message: 'global.errors.required' }
	} else if (!isValidPassword(values.password)) {
		errors.password = { type: 'format', message: 'global.errors.format' }
	}
	if (!values.repeatedPassword) {
		errors.repeatedPassword = { type: 'required', message: 'global.errors.required' }
	} else if (!isValidPassword(values.repeatedPassword)) {
		errors.repeatedPassword = { type: 'format', message: 'global.errors.format' }
	}

	if (values.password && values.repeatedPassword && values.password !== values.repeatedPassword) {
		errors.repeatedPassword = { type: 'match', message: 'updatePassword.errorMatch' }
	}
	return { values, errors }
}

const UpdatePassword: React.FC<UpdatePasswordProps> = ({ initPassword = false }) => {
	const { enqueueSnackbar } = useSnackbar()
	const [searchParams] = useSearchParams()
	const navigate = useNavigate()

	const suffixInit = initPassword ? 'Init' : ''
	const intl = useIntl()
	const updatePasswordForm = useForm<UpdatePasswordForm>({
		mode: 'onSubmit',
		defaultValues: {
			password: '',
			repeatedPassword: ''
		},
		resolver
	})

	const [disableUpdate, setDisableUpdate] = useState<boolean>(false)

	// If token is invalid, we inform user and disable update password
	const urlToken = searchParams.get('token') || ''
	useEffect(() => {
		checkTokenValidity(urlToken)
			.catch(() => setDisableUpdate(true))
	}, [urlToken])

	const handleSubmit = (values: UpdatePasswordForm) => {
		return updatePassword(urlToken, values)
			.then(() => {
				enqueueSnackbar(intl.formatMessage({ id: `updatePassword.success${suffixInit}`, defaultMessage: 'Update password request succeed', description: 'submit success' }), { variant: SNACK_VARIANT.SUCCESS })
				navigate(APP_ROUTES.LOGIN)
			})
			.catch(() => enqueueSnackbar(intl.formatMessage({ id: 'global.errors.occured', defaultMessage: 'Error occured', description: 'error' }), { variant: SNACK_VARIANT.ERROR }))
	}

	return <StyledLoginContainer onSubmit={updatePasswordForm.handleSubmit(handleSubmit)}>
		<Grid item xs={12} height="100%" container>
			<StyledLoginImg item xs={6} />
			<StyledLoginForm item xs={6} container>
				<Grid item xs={12} alignItems="flex-start" justifyContent="center" sx={{ maxHeight: '70vh', overflow: 'auto' }}>
					<Grid item xs={12} textAlign="center" sx={{ mb: '16px' }}><img src={logo} alt={logo} height={62} /></Grid>
					<Grid item xs={12} container justifyContent="center">
						<Grid item xs={12} textAlign="center"><StyledLoginTitle><FormattedMessage id={`updatePassword.title${suffixInit}`} defaultMessage="Init password" description="init password title" /></StyledLoginTitle></Grid>
						<Grid item xs={10} sx={{ mb: '12px' }}><Typography variant="body2"><FormattedMessage id={`updatePassword.description${suffixInit}`} defaultMessage="init password description"
						                                                                                     description="login description" /></Typography></Grid>
						<Grid item xs={10} sx={{ mb: disableUpdate ? '11px' : '31px', whiteSpace: 'pre-line' }}><Typography variant="caption"><FormattedMessage id="updatePassword.caption" defaultMessage="Init password caption"
						                                                                                                                                        description="init password caption" /></Typography></Grid>
						{disableUpdate && <Grid item xs={10} sx={{ mb: '10px' }} textAlign="center"><StyledErrorToken variant="body2"><FormattedMessage id="updatePassword.invalidToken" defaultMessage="Invalid token"
						                                                                                                                                description="error message" /></StyledErrorToken></Grid>}
						<Grid item xs={12} container justifyContent="center">
							<Grid item xs={5}>
								<FormPassword<UpdatePasswordForm>
									id="password"
									name="password"
									label={intl.formatMessage({ id: `updatePassword.form.password${suffixInit}`, defaultMessage: 'Password', description: 'password input' })}
									control={updatePasswordForm.control}
								/>
							</Grid>
						</Grid>
						<Grid item xs={12} container justifyContent="center">
							<Grid item xs={5}>
								<FormPassword<UpdatePasswordForm>
									id="repeatedPassword"
									name="repeatedPassword"
									label={intl.formatMessage({ id: 'updatePassword.form.repeatedPassword', defaultMessage: 'Repeated password', description: 'repeated password input' })}
									control={updatePasswordForm.control}
								/>
							</Grid>
						</Grid>
						<Grid item xs={12} textAlign="center" sx={{ mb: '12px' }}>
							<SubmitButton
								label="global.buttons.validate"
								loading={updatePasswordForm.formState.isSubmitting}
								disabled={disableUpdate}
							/>
						</Grid>
					</Grid>
				</Grid>
			</StyledLoginForm>
		</Grid>
	</StyledLoginContainer>
}

export default UpdatePassword
