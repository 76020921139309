import { AppBar, Drawer, List, ListItemButton, styled } from '@mui/material'
import { APPBAR_HEIGHT, DRAWER_WIDTH } from '../../utils/constants'

export const StyledAppBar = styled(AppBar)(
	({ theme }) => ({
		height: APPBAR_HEIGHT,
		zIndex: theme.zIndex.drawer + 1
	})
)

export const StyledDrawer = styled(Drawer)(
	({ theme }) => ({
		width: DRAWER_WIDTH,
		flexShrink: 0,
		'& .MuiDrawer-paper': {
			width: DRAWER_WIDTH,
			border: 'transparent',
			backgroundColor: theme.palette.primary.main,
			borderRadius: '0px 0px 16px 0px',
		}
	})
)

export const StyledDrawerList = styled(List)({
	width: '100%',
	marginLeft: 16,
	marginTop: 16
})

export const StyledListItemButton = styled(ListItemButton)({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	padding: '16px 32px 16px 16px',
	width: 234,
	height: 56,
	flex: 'none',
	order: 0,
	alignSelf: 'stretch',
	flexGrow: 0
})
