import { Button, styled } from '@mui/material'

export const StyledPrimaryButton = styled(Button)({
	padding: 12,
	borderRadius: 8,
	'& .MuiTypography-root': {
		color: 'white'
	},
	'& .MuiCircularProgress-root': {
		height: '20px !important',
		width: '20px !important',
		marginRight: 10,
		color: 'white'
	}
})
