import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Grid } from '@mui/material'
import FormSelect from '../../../components/form/FormSelect'
import { FiltersFormProps, FiltersFormValues, Referential } from './filters'
import PeriodDatePicker from '../../../components/form/period/PeriodDatePicker'
import SearchIcon from '@mui/icons-material/Search'
import { useForm } from 'react-hook-form'
import { DATA_TYPE, REFERENTIAL_TYPE, SNACK_VARIANT } from '../../../utils/constants'
import { DateTime } from 'luxon'
import { useIntl } from 'react-intl'
import { getReferentialList } from '../../../modules/activity/services/activityApi'
import { useSnackbar } from 'notistack'
import Loader from '../../../components/layout/Loader'
import { SubmitButton } from '../../button/button'
import { getMondayOfCurrentWeek } from '../../../utils/dateUtils'

const FiltersContainer: React.FC<FiltersFormProps> = ({
	                                                      onSubmit,
	                                                      children,
	                                                      target,
	                                                      selectReferentialDefaultValue
                                                      }) => {
	const intl = useIntl()
	const { enqueueSnackbar } = useSnackbar()
	const [selectReferentialList, setReferentialList] = useState<Referential[]>(selectReferentialDefaultValue ? [selectReferentialDefaultValue] : [])
	const [loading, setLoading] = useState(true)
	const filterForm = useForm<FiltersFormValues>({
		mode: 'onSubmit',
		defaultValues: {
			periodType: DATA_TYPE.WEEK,
			selectReferential: selectReferentialDefaultValue?.code,
			period: getMondayOfCurrentWeek(DateTime.now())?.toISO()
		}
	})

	const handleSubmit = useCallback((values: FiltersFormValues) => {
		setLoading(true)
		return onSubmit(values)
			.finally(() => setLoading(false))
	}, [setLoading, onSubmit])

	// Get referential
	useEffect(() => {
		if (target === REFERENTIAL_TYPE.LABORATORY || target === REFERENTIAL_TYPE.CLIENT) {
			getReferentialList(target)
				.then(setReferentialList)
				.catch(() =>
					enqueueSnackbar(intl.formatMessage({
						id: 'global.errors.occured',
						defaultMessage: 'Error occured',
						description: 'error'
					}), { variant: SNACK_VARIANT.ERROR })
				)
		}
	}, [target, intl, enqueueSnackbar])

	// Call on first mount
	useEffect(() => {
		handleSubmit({
			periodType: DATA_TYPE.WEEK,
			selectReferential: selectReferentialDefaultValue?.code,
			period: DateTime.now().toISO()
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const selectReferentialOptions = useMemo(() => selectReferentialList.map(entity => ({
		label: entity.name,
		value: entity.code
	})), [selectReferentialList])

	const dataTypeOptions = useMemo(() => Object.values(DATA_TYPE).map(value => ({
		label: intl.formatMessage({
			id: `enums.dataType.${value}`,
			defaultMessage: 'Data type',
			description: 'Data Type option'
		}),
		value: value
	})), [intl])

	const watchPeriodType = filterForm.watch('periodType', DATA_TYPE.WEEK)

	return (
		<>
			<form onSubmit={filterForm.handleSubmit(handleSubmit)}>
				<Grid item xs={12} container alignItems="flex-start"
				      sx={{ mt: '16px', pr: '16px' }}>
					<Grid item container xs={12} xl={10}>
						{
							selectReferentialDefaultValue && (
								<Grid item xs={4} sm={2} sx={{ mr: '16px' }}>
									<FormSelect<FiltersFormValues>
										id="selectReferential"
										name="selectReferential"
										items={selectReferentialOptions}
										control={filterForm.control}
									/>
								</Grid>
							)
						}
						<Grid item xs={6} sm={3} sx={{ mr: '16px' }}>
							<FormSelect<FiltersFormValues>
								id="periodType"
								name="periodType"
								items={dataTypeOptions}
								control={filterForm.control}
							/>
						</Grid>
						<Grid item xs={6} sm={3} sx={{ mr: '16px' }}>
							<PeriodDatePicker<FiltersFormValues>
								name="period"
								control={filterForm.control}
								period={watchPeriodType}
							/>
						</Grid>
						<Grid item>
							<SubmitButton icon={<SearchIcon />} />
						</Grid>
					</Grid>
				</Grid>
			</form>
			{
				loading ? <Loader /> : children
			}
		</>

	)
}

export default FiltersContainer
