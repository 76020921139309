import React from 'react'
import { Control, FieldPath, FieldValues, useController } from 'react-hook-form'
import { StyledError, StyledField } from './Form.styles'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { FormattedMessage } from 'react-intl'

type FormSelectItem = {
    label: string
    value: string
}

type FormSelectProps<TFieldValues extends FieldValues = FieldValues> = {
    id: string
    label?: string
    items: FormSelectItem[]
    required?: boolean
    disabled?: boolean
    control: Control<TFieldValues>
    name: FieldPath<TFieldValues>
}

const FormSelect = <TFieldValues extends FieldValues = FieldValues>({
                                                                        id,
                                                                        label,
                                                                        items,
                                                                        required,
                                                                        disabled,
                                                                        control,
                                                                        name
                                                                    }: FormSelectProps<TFieldValues>) => {
    const { field, fieldState } = useController({ name, control })

    return (
        <StyledField hasError={!!fieldState.error}>
            <FormControl fullWidth {...field} disabled={disabled} error={!!fieldState.error}>
                <InputLabel id={`select-field-label-${id}`}>{label && `${label} ${required ? '*' : ''}`}</InputLabel>
                <Select
                    labelId={`select-field-label-${id}`}
                    id={`select-field-${id}`}
                    label={label && `${label} ${required ? '*' : ''}`}
                    disabled={disabled}
                    error={!!fieldState.error}
                    {...field}
                >
                    {items.map((item, index) => <MenuItem key={`${name}-${index}`}
                                                          value={item.value}>{item.label}</MenuItem>)}
                </Select>
            </FormControl>
            {!!fieldState.error &&
                <StyledError><FormattedMessage id={fieldState.error.message} defaultMessage="Select error"
                                               description="select error" /></StyledError>}
        </StyledField>
    )
}

export default FormSelect
