import { colors } from './colors'
import { createTheme } from '@mui/material'

const theme = createTheme({
	typography: {
		h1: {
			fontSize: 24,
			fontWeight: 700,
			fontFamily: 'Oswald',
			color: colors.h1
		},
		h2: {
			fontSize: 18,
			fontWeight: 600,
			fontFamily: 'Oswald',
			color: colors.text
		},
		body1: {
			fontSize: 16,
			fontWeight: 400,
			fontFamily: 'Lato',
			color: colors.text
		},
		body2: {
			fontSize: 14,
			fontWeight: 400,
			fontFamily: 'Lato',
			color: colors.text
		},
		caption: {
			fontSize: 12,
			fontWeight: 300,
			fontFamily: 'Lato',
			color: colors.text
		},
		header1: {
			fontSize: 16,
			fontWeight: 700,
			fontFamily: 'Lato',
			color: colors.text
		},
		header2: {
			fontSize: 14,
			fontWeight: 700,
			fontFamily: 'Lato',
			color: colors.text
		},
		list1: {
			fontSize: 14,
			fontWeight: 400,
			fontFamily: 'Lato',
			color: colors.text
		},
		list2: {
			fontSize: 12,
			fontWeight: 400,
			fontFamily: 'Lato',
			color: colors.text
		},
		menu: {
			fontSize: 14,
			fontWeight: 600,
			fontFamily: 'Oswald',
			color: colors.text
		},
		indicator1: {
			fontSize: 32,
			fontWeight: 900,
			fontFamily: 'Lato',
			color: colors.text
		},
		indicator2: {
			fontSize: 24,
			fontWeight: 900,
			fontFamily: 'Lato',
			color: colors.text
		}
	},
	palette: {
		primary: {
			main: colors.primary
		},
		secondary: {
			main: colors.secondary
		}
	}
})

export default theme
