import { DateTime } from 'luxon'

export const getMondayOfLastWeek = (date: Date) => DateTime.fromJSDate(date).minus({ week: 1 }).toJSDate()

export const getLastYear = (date: Date) => date.getFullYear() - 1

export const getMondayOfCurrentWeek = (date?: DateTime | null) => date ? date.minus({days: date.weekday - 1}) : date

export const getFilenameWithDate = (filename: string) => `${DateTime.now().toISODate()}_${filename}`

export const arePeriodEqual = (period: Date, dateTime: DateTime) => period.toString().trim() === dateTime.toISO().substring(0, 10)