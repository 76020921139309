import React, { useContext, useEffect, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { StyledSection, StyledSectionTitle } from '../../components/layout/Section.styles'
import { useForm } from 'react-hook-form'
import FormInput from '../../components/form/FormInput'
import { Grid, IconButton, InputAdornment, Paper, styled, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material'
import { Add, Check, Delete, KeyboardArrowLeft, KeyboardArrowRight, Search, Send, ModeEdit } from '@mui/icons-material'
import { PrimaryButton } from '../../components/button/button'
import AddUsers from './components/AddUsers'
import { getUsers } from './services/usersApi'
import { PAGE_SIZE, USER_PREFERENCES } from '../../utils/constants'
import HandleUsersStatus from './components/HandleUsersStatus'
import GuestNotification from './components/GuestNotification'
import { AppContext } from '../appData/AppContext'
import { AppModel } from '../appData/appModel'
import TableHeader from '../../components/layout/TableHeader'
import UpdateUserRoleDialog from './components/UpdateUserRoleDialog'

type SearchForm = {
	query: string
}

const COLUMNS = ['lastname', 'firstname', 'email', 'role', 'status']

const StyledEmptyState = styled(Typography)({
	position: 'absolute',
	left: 'calc(50% - 50px)',
	paddingTop: 10
})

const StyledPageNumber = styled('span')({
	background: '#C7C7C7',
	borderRadius: 16,
	width: 32,
	height: 32,
	display: 'grid',
	alignItems: 'center'
})

const UsersPage = () => {
	const intl = useIntl()
	const { user } = useContext<AppModel>(AppContext)
	const [openAddForm, setOpenAddForm] = useState<boolean>(false)
	const [openConfirmStatus, setOpenConfirmStatus] = useState<boolean>(false)
	const [openSendNotification, setOpenSendNotification] = useState<boolean>(false)
	const [updateUserRole, setUpdateUserRole] = useState<User | undefined>(undefined)
	const [selectedRow, setSelectedRow] = useState<User | undefined>(undefined)
	const [userPage, setUserPage] = useState<UserPage | undefined>()
	const [query, setQuery] = useState<string>('')
	const searchForm = useForm<SearchForm>({
		mode: 'onSubmit',
		defaultValues: {
			query: ''
		}
	})

	const currentPage = useMemo(() => userPage?.number || 0, [userPage])
	const hasPagination = useMemo(() => (userPage?.totalElements || 0) > PAGE_SIZE, [userPage])

	const search = (values: SearchForm) => {
		setQuery(values.query)
		getUserPage(values.query, 0)
	}

	const getUserPage = (query: string, page: number) => {
		getUsers(query, page)
			.then(setUserPage)
	}

	const handlePage = (page: number) => {
		getUserPage(query, page)
	}

	const renderStatusRow = (row: User) => {
		if (row.preferences && row.preferences[USER_PREFERENCES.IS_GUEST] === 'true') {
			return <FormattedMessage id="enums.status.guest" defaultMessage="Guest" description="status option" />
		}
		return <FormattedMessage id={`enums.status.${row.disabled ? 'disabled' : 'enabled'}`} defaultMessage="status" description="status option" />
	}

	const renderActionRow = (row: User) => {
		if (user && row.email === user.email) {
			return <></>
		}
		if (row.preferences && row.preferences[USER_PREFERENCES.IS_GUEST] === 'true') {
			return <IconButton
				aria-label="handle user status"
				onClick={() => {
					setOpenSendNotification(true)
					setSelectedRow(row)
				}}
				edge="end"
			>
				<Send />
			</IconButton>
		}
		return <IconButton
			aria-label="handle user status"
			onClick={() => {
				setOpenConfirmStatus(true)
				setSelectedRow(row)
			}}
			edge="end"
		>
			{row.disabled ? <Check /> : <Delete />}
		</IconButton>
	}

	useEffect(() => {
		getUserPage('', 0)
	}, [])

	return <Paper>
		<StyledSection elevation={0}>
			<form onSubmit={searchForm.handleSubmit(search)}>
				<StyledSectionTitle variant="h2"><FormattedMessage id="users.title" defaultMessage="Users list" description="Users list title" /></StyledSectionTitle>
				<Grid item xs={12} container justifyContent="space-between" alignItems="flex-start">
					<Grid item xs={4} sm={2}>
						<FormInput<SearchForm>
							id="query"
							name="query"
							placeholder={intl.formatMessage({ id: 'users.search', defaultMessage: 'Search', description: 'search input' })}
							required
							control={searchForm.control}
							endAdornment={<InputAdornment position="end">
								<IconButton
									aria-label="submit search"
									edge="end"
									type="submit"
								>
									<Search />
								</IconButton>
							</InputAdornment>}
						/>
					</Grid>
					<Grid item xs={4} sx={{ textAlign: 'end' }}>
						<PrimaryButton
							label="users.add"
							icon={<Add />}
							onClick={() => setOpenAddForm(true)}
						/>
					</Grid>
				</Grid>
			</form>
		</StyledSection>
		<TableContainer sx={{ minHeight: '10vh', maxHeight: hasPagination ? '58vh' : '64vh', pb: '10px' }}>
			<Table stickyHeader aria-label="user table">
				<TableHeader>
					<TableRow>
						{COLUMNS.map((column) => (
							<TableCell key={column}>
								<Typography variant="header1">
									<FormattedMessage id={`global.user.${column}`} defaultMessage={column} description="header column name" />
								</Typography>
							</TableCell>
						))}
						<TableCell key="action-header" sx={{ width: '80px' }} />
					</TableRow>
				</TableHeader>
				<TableBody>
					{userPage?.content
						.map((user: User, index: number) => <TableRow key={`user-row-${index}`}>
							<TableCell><Typography variant="list1">{user.lastname}</Typography></TableCell>
							<TableCell><Typography variant="list1">{user.firstname}</Typography></TableCell>
							<TableCell><Typography variant="list1">{user.email}</Typography></TableCell>
							<TableCell>
								<Typography variant="list1">
									<FormattedMessage id={`enums.role.${(user.roles && user.roles[0] && user.roles[0].code) || ''}`} defaultMessage="user role" description="user role" />
								</Typography>
							</TableCell>
							<TableCell><Typography variant="list1">{renderStatusRow(user)}</Typography></TableCell>
							<TableCell sx={{ padding: 0 }}>
								<IconButton
									aria-label="update user role"
									onClick={() => setUpdateUserRole(user)}
								>
									<ModeEdit />
								</IconButton>
								{renderActionRow(user)}
							</TableCell>
						</TableRow>)}
					{userPage?.totalElements === 0 && <StyledEmptyState variant="list1"><FormattedMessage id="users.noData" defaultMessage="No users" description="Users empty state" /></StyledEmptyState>}
				</TableBody>
			</Table>
		</TableContainer>
		{hasPagination && <Grid item xs={12} sx={{ pt: '10px' }} container alignItems="center" textAlign="center" justifyContent="end">
			<IconButton
				onClick={() => handlePage(currentPage - 1)}
				disabled={currentPage === 0}
				aria-label="prevous page"
			>
				<KeyboardArrowLeft />
			</IconButton>
			<StyledPageNumber>{currentPage + 1}</StyledPageNumber>
			<IconButton
				onClick={() => handlePage(currentPage + 1)}
				disabled={(currentPage + 1) >= (userPage?.totalPages || 0)}
				aria-label="next page"
			>
				<KeyboardArrowRight />
			</IconButton>
		</Grid>}

		{openAddForm && <AddUsers
			open
			reloadTable={() => getUserPage(query, 0)}
			onClose={() => setOpenAddForm(false)}
		/>}
		{(openConfirmStatus && selectedRow) && <HandleUsersStatus
			open
			selectedRow={selectedRow}
			reloadTable={() => getUserPage(query, 0)}
			onClose={() => {
				setOpenConfirmStatus(false)
				setSelectedRow(undefined)
			}}
		/>}
		{(openSendNotification && selectedRow) && <GuestNotification
			open
			userId={selectedRow.id}
			reloadTable={() => getUserPage(query, 0)}
			onClose={() => {
				setOpenConfirmStatus(false)
				setSelectedRow(undefined)
			}}
		/>}
		{updateUserRole && <UpdateUserRoleDialog
			user={updateUserRole}
			reloadTable={() => getUserPage(query, 0)}
			onClose={() => setUpdateUserRole(undefined)}
		/>}
	</Paper>
}

export default UsersPage
