import React, { useMemo } from 'react'
import { FieldErrors, Resolver, useForm } from 'react-hook-form'
import { SNACK_VARIANT, USER_ROLES } from '../../../utils/constants'
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material'
import FormInput from '../../../components/form/FormInput'
import { FormattedMessage, useIntl } from 'react-intl'
import { Close as CloseIcon } from '@mui/icons-material'
import { SecondaryButton, SubmitButton } from '../../../components/button/button'
import FormSelect from '../../../components/form/FormSelect'
import { isValidEmail } from '../../../utils/formUtils'
import { createUser } from '../services/usersApi'
import { useSnackbar } from 'notistack'

type AddUsersProps = {
	open: boolean
	reloadTable: () => void
	onClose: () => void
}

const resolver: Resolver<AddUsersForm> = async (values) => {
	const errors: FieldErrors<AddUsersForm> = {}

	if (!values.firstname) {
		errors.firstname = { type: 'required', message: 'global.errors.required' }
	}
	if (!values.lastname) {
		errors.lastname = { type: 'required', message: 'global.errors.required' }
	}
	if (!values.email) {
		errors.email = { type: 'required', message: 'global.errors.required' }
	} else if (!isValidEmail(values.email)) {
		errors.email = { type: 'format', message: 'global.errors.format' }
	}
	return { values, errors }
}

const AddUsers: React.FC<AddUsersProps> = ({ open, reloadTable, onClose }) => {
	const intl = useIntl()
	const { enqueueSnackbar } = useSnackbar()
	const addUsersForm = useForm<AddUsersForm>({
		mode: 'onSubmit',
		defaultValues: {
			firstname: '',
			lastname: '',
			email: '',
			role: USER_ROLES.MEMBER
		},
		resolver
	})

	const submit = (values: AddUsersForm) => {
		return createUser(values)
			.then(() => {
				onClose()
				reloadTable()
				enqueueSnackbar(intl.formatMessage({ id: `users.addSuccess`, defaultMessage: 'Users has been created', description: 'submit success' }), { variant: SNACK_VARIANT.SUCCESS })
			})
			.catch(e => {
				// Email error handler
				if (e && e.email) {
					addUsersForm.setError('email', { type: e.email.id, message: `global.errors.${e.email.id}` })
					enqueueSnackbar(intl.formatMessage({ id: 'global.errors.formWithErrors', defaultMessage: 'Form with errors', description: 'form error' }), { variant: SNACK_VARIANT.ERROR })
				} else {
					enqueueSnackbar(intl.formatMessage({ id: 'global.errors.occured', defaultMessage: 'Error occured', description: 'error' }), { variant: SNACK_VARIANT.ERROR })
				}
			})
	}

	const roleItems = useMemo(() => Object.values(USER_ROLES)
		.map(u => ({
			label: intl.formatMessage({ id: `enums.role.${u}`, defaultMessage: u, description: 'role option' }),
			value: u
		})), [intl])

	return <Dialog open={open} onClose={onClose} aria-labelledby="dialog" fullWidth>
		<form onSubmit={addUsersForm.handleSubmit(submit)}>
			<DialogTitle sx={{ mb: '11px', pt: '20px', pX: '20px' }}>
				<Typography variant="h2"><FormattedMessage id="users.add" defaultMessage="Add user" description="User dialog title" /></Typography>
				{onClose && (
					<IconButton
						aria-label="close"
						onClick={onClose}
						sx={{
							position: 'absolute',
							right: 8,
							top: 12
						}}
					>
						<CloseIcon />
					</IconButton>
				)}
			</DialogTitle>
			<DialogContent sx={{ pt: '5px !important', pX: '20px' }}>
				<Grid item xs={12} container spacing={2}>
					<Grid item xs={6}>
						<FormInput<AddUsersForm>
							id="firstname"
							name="firstname"
							label={intl.formatMessage({ id: 'global.user.firstname', defaultMessage: 'Firstname', description: 'firstname input' })}
							required
							control={addUsersForm.control}
						/>
					</Grid>
					<Grid item xs={6}>
						<FormInput<AddUsersForm>
							id="lastname"
							name="lastname"
							label={intl.formatMessage({ id: 'global.user.lastname', defaultMessage: 'Lastname', description: 'lastname input' })}
							required
							control={addUsersForm.control}
						/>
					</Grid>
				</Grid>
				<Grid item xs={12} container spacing={2}>
					<Grid item xs={6}>
						<FormInput<AddUsersForm>
							id="email"
							name="email"
							label={intl.formatMessage({ id: 'global.user.email', defaultMessage: 'Email', description: 'email input' })}
							required
							control={addUsersForm.control}
						/>
					</Grid>
					<Grid item xs={6}>
						<FormSelect<AddUsersForm>
							id="role"
							name="role"
							label={intl.formatMessage({ id: 'global.user.role', defaultMessage: 'Role', description: 'role select' })}
							items={roleItems}
							required
							control={addUsersForm.control}
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions sx={{ paddingX: '20px', pb: '20px' }}>
				<SecondaryButton
					label="global.buttons.cancel"
					onClick={onClose}
				/>
				<SubmitButton
					label="global.buttons.validate"
					loading={addUsersForm.formState.isSubmitting}
				/>
			</DialogActions>
		</form>
	</Dialog>
}

export default AddUsers
