import React from 'react'
import { AppModel } from './appModel'

export const AppContext = React.createContext<AppModel>({
	user: undefined,
	token: undefined,
	connected: false,
	taskInProgress: false,
	updateUser: () => {},
	handleConnectedUser: () => {},
	setToken: () => {},
	handleTaskInProgress: () => {}
})
