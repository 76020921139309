import React, { useLayoutEffect, useMemo } from 'react'
import ChartContainer from '../../../components/charts/ChartContainer'
import { FormattedMessage, useIntl } from 'react-intl'
import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import { CURRENT_YEAR, EXPORT_OPTIONS } from '../../../utils/constants'
import { formatDataIpvgenoTotal } from '../services/dashboardUtils'
import * as am5plugins_exporting from '@amcharts/amcharts5/plugins/exporting'
import { Exporting } from '@amcharts/amcharts5/plugins/exporting'
import { getFilenameWithDate } from '../../../utils/dateUtils'

type IpvGenoTotalProps = {
	dataThisYear: DataTotalIpvgeno[]
	dataPreviousYear: DataTotalIpvgeno[]
	updatedDate: string
}

const CHART_NAME = 'chart-ipvgenoTotal'
let exporting: Exporting | undefined = undefined

const IpvGenoTotal: React.FC<IpvGenoTotalProps> = ({ dataThisYear, dataPreviousYear, updatedDate }) => {
	const intl = useIntl()
	const formattedData = useMemo(() => formatDataIpvgenoTotal(CURRENT_YEAR, dataThisYear, dataPreviousYear, intl), [dataThisYear, dataPreviousYear, intl])

	useLayoutEffect(() => {
		const root = am5.Root.new(CHART_NAME)
		root.setThemes([am5themes_Animated.new(root)])
		root.numberFormatter.set('numberFormat', '#')
		const chart = root.container.children.push(am5xy.XYChart.new(root, {
			panX: false,
			panY: false,
			wheelX: 'none',
			wheelY: 'none',
			layout: root.verticalLayout,
			reverseChildren: true
		}))

		exporting = am5plugins_exporting.Exporting.new(root, {
			dataSource: formattedData,
			...EXPORT_OPTIONS,
			filePrefix: getFilenameWithDate(intl.formatMessage({ id: 'global.charts.export.filename.ipvGenoTotal', defaultMessage: CHART_NAME, description: 'Chart name' })),
			dataFields: {
				[CURRENT_YEAR - 1]: intl.formatMessage({ id: 'dashboard.charts.ipvGenoTotal.yearSerie', description: 'Serie\'s name', defaultMessage: `${CURRENT_YEAR - 1}` }, { year: CURRENT_YEAR - 1}),
				[CURRENT_YEAR]: intl.formatMessage({ id: 'dashboard.charts.ipvGenoTotal.yearSerie', description: 'Serie\'s name', defaultMessage: `${CURRENT_YEAR}` }, { year: CURRENT_YEAR}),
				month: intl.formatMessage({ id: 'global.charts.export.headers.month', defaultMessage: 'Month', description: 'Month header' }),
			},
			dataFieldsOrder: ['month', `${CURRENT_YEAR - 1}`, `${CURRENT_YEAR}`]
		})

		// Legend
		const legend = chart.children.push(
			am5.Legend.new(root, {
				centerX: am5.p50,
				x: am5.p50,
				y: 0
			})
		)

		// Axis
		const xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
			categoryField: 'month',
			renderer: am5xy.AxisRendererX.new(root, {
				cellStartLocation: 0.1,
				cellEndLocation: 0.9
			}),
			tooltip: am5.Tooltip.new(root, {})
		}))
		xAxis.get('renderer').labels.template.setAll({
			location: 0.5,
			multiLocation: 0.5
		})
		xAxis.data.setAll(formattedData)

		const yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
			renderer: am5xy.AxisRendererY.new(root, {})
		}))

		// Series
		const makeSeries = (name: string, fieldName: string, color: string) => {
			const series = chart.series.push(am5xy.ColumnSeries.new(root, {
				name: name,
				xAxis: xAxis,
				yAxis: yAxis,
				valueYField: fieldName,
				categoryXField: 'month'
			}))
			series.columns.template.setAll({
				tooltipText: '[bold]{name}[/]\n{categoryX}: {valueY}',
				width: am5.percent(90),
				tooltipY: 0
			})
			series.data.setAll(formattedData)
			series.set('fill', am5.color(color))
			series.appear()
			legend.data.push(series)
		}

		makeSeries(intl.formatMessage({
			id: 'dashboard.charts.ipvGenoTotal.yearSerie',
			description: 'Serie\'s name',
			defaultMessage: `${CURRENT_YEAR - 1}`
		}, { year: CURRENT_YEAR - 1 }), `${CURRENT_YEAR - 1}`, '#256BC1')
		makeSeries(intl.formatMessage({
			id: 'dashboard.charts.ipvGenoTotal.yearSerie',
			description: 'Serie\'s name',
			defaultMessage: `${CURRENT_YEAR}`
		}, { year: CURRENT_YEAR }), `${CURRENT_YEAR}`, '#34DD50')

		chart.appear(1000, 100)

		return () => root.dispose()
	}, [formattedData, intl])

	return <ChartContainer
		title={<FormattedMessage id="dashboard.charts.ipvGenoTotal.title" defaultMessage="IPVgeno" description="Chart ipvGeno title" values={{ year: CURRENT_YEAR }} />}
		tooltip={intl.formatMessage({ id: 'dashboard.charts.ipvGenoTotal.tooltip', defaultMessage: 'IPVgeno tooltip', description: 'Chart IPVgeno tooltip' })}
		updatedDate={updatedDate}
		exportData={() => exporting?.download('xlsx')}
	>
		<div id={CHART_NAME} style={{ width: '100%', height: 400 }}></div>
	</ChartContainer>
}

export default IpvGenoTotal
