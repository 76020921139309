import { LOCAL_STORAGE_ITEM } from './constants'

/**
 * Get user token
 */
export const getUserToken = (): string | null => localStorage.getItem(LOCAL_STORAGE_ITEM.TOKEN)

/**
 * Store user token
 * @param token
 */
export const storeUserToken = (token: string) => {
	localStorage.setItem(LOCAL_STORAGE_ITEM.TOKEN, token)
}

/**
 * Remove user token
 */
export const removerUserToken = () => {
	localStorage.removeItem(LOCAL_STORAGE_ITEM.TOKEN)
}
