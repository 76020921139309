import React, { ReactNode } from 'react'
import { Stack, styled, Typography } from '@mui/material'
import { FormattedNumber } from 'react-intl'

type EllipseIndicatorProps = {
    smallSize?: boolean
    color: string
    label: ReactNode | string
    count: number
    important?: boolean
}

const EllipseContainer = styled(Stack,{
        shouldForwardProp : (prop: string) =>  prop !== 'smallSize'
    }
)<{ smallSize: boolean, color: string }>(
    ({ smallSize, color, theme }) => ({
        [theme.breakpoints.up('lg')] : {
            height: smallSize ? '120px' : '170px',
            width: smallSize ? '120px' : '170px',
        },
        '@media (max-width:1540px)' : {
            height: '120px',
            width: '120px',
        },
        border: 'solid 6px',
        borderRadius: '50%',
        borderColor: color,
        alignItems: 'center',
        justifyContent: 'center'
    })
)

const EllipseIndicator: React.FC<EllipseIndicatorProps> = ({
                                                               label,
                                                               count,
                                                               color,
                                                               smallSize = false,
                                                               important = false
                                                           }) => {
    const colorCount = important ? '#FF0000' : '#404040'
    return <EllipseContainer color={color} smallSize={smallSize}>
        <Typography variant="indicator2" sx={{ color: colorCount }}><FormattedNumber value={count} /></Typography>
        <Typography variant="body1">{label}</Typography>
    </EllipseContainer>
}

export default EllipseIndicator
