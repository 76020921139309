import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import ConfirmDialog from '../../../components/dialog/ConfirmDialog'
import { sendGuestNotification } from '../services/usersApi'
import { useSnackbar } from 'notistack'
import { SNACK_VARIANT } from '../../../utils/constants'

type GuestNotificationProps = {
	open: boolean
	userId: string
	reloadTable: () => void
	onClose: () => void
}

const GuestNotification: React.FC<GuestNotificationProps> = ({ open, userId, reloadTable, onClose }) => {
	const { enqueueSnackbar } = useSnackbar()
	const intl = useIntl()
	const [loading, setLoading] = useState<boolean>(false)
	const sendNotification = () => {
		setLoading(true)
		sendGuestNotification(userId)
			.then(() => {
				reloadTable()
				enqueueSnackbar(intl.formatMessage({ id: `users.guestNotification.success`, defaultMessage: 'Send notification succeed', description: 'submit success' }), { variant: SNACK_VARIANT.SUCCESS })
				onClose()
			})
			.catch(() => enqueueSnackbar(intl.formatMessage({ id: 'global.errors.occured', defaultMessage: 'Error occured', description: 'error' }), { variant: SNACK_VARIANT.ERROR }))
			.finally(() => setLoading(false))
	}
	return <ConfirmDialog
		open={open}
		title={`users.guestNotification.title`}
		description={`users.guestNotification.description`}
		confirmLabel={`users.guestNotification.confirm`}
		confirmLoading={loading}
		confirmClick={sendNotification}
		onClose={onClose}
	/>
}

export default GuestNotification
