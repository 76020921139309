import React, { useContext } from 'react'
import { Outlet, Navigate } from 'react-router'
import AppBar from './components/layout/AppBar'
import { Box, styled } from '@mui/material'
import { APPBAR_HEIGHT, DRAWER_WIDTH } from './utils/constants'
import { AppModel } from './modules/appData/appModel'
import { AppContext } from './modules/appData/AppContext'
import Loader from './components/layout/Loader'

interface BoxLayoutProps {
	openDrawer: boolean
}

const BoxLayout = styled(Box, {
	shouldForwardProp : (prop: string) =>  prop !== 'openDrawer'
})<BoxLayoutProps>(
	({ openDrawer }) => ({
			marginTop: APPBAR_HEIGHT,
			marginLeft: openDrawer ? DRAWER_WIDTH : 0,
			padding: 32
		}
	))

const App = () => {
	const { connected, taskInProgress } = useContext<AppModel>(AppContext)
	const [openDrawer, setOpenDrawer] = React.useState<boolean>(true)
	const handleDrawer = () => setOpenDrawer(state => !state)

	if (!connected) {
		return <Navigate to="/login" />
	}

	return <>
		<AppBar openDrawer={openDrawer} handleDrawer={handleDrawer} />
		<BoxLayout openDrawer={openDrawer}>
			{taskInProgress ? <Loader /> : <Outlet />}
		</BoxLayout>
	</>
}

export default App
