import { styled, TableHead } from '@mui/material'

const TableHeader = styled(TableHead)({
	'& .MuiTableRow-root': {
		'& .MuiTableCell-root': {
			backgroundColor: '#F0F0F0'
		}
	}
})

export default TableHeader
