import React, { useCallback, useMemo, useState } from 'react'
import { Grid, Typography } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import Indicator from '../../../components/layout/Indicator'
import TypesByLaboratory from './components/TypesByLaboratory'
import Samples from './components/Samples'
import FiltersContainer from '../../../components/form/filters/FiltersContainer'
import ProcessButton from '../components/ProcessButton'
import { FiltersFormValues } from '../../../components/form/filters/filters'
import { API_CALL_ACTIVITY_KEY, DATA_TYPE, GLOBAL_CONTENT_TYPE, SNACK_VARIANT } from '../../../utils/constants'
import { getActivityAlertTiles, getActivityGraphs, getGlobalExportFile } from '../services/activityApi'
import EmptyState from '../../../components/layout/EmptyState'
import { getLastYear, getMondayOfLastWeek } from '../../../utils/dateUtils'
import { useSnackbar } from 'notistack'
import { useDisableExport } from '../services/activityUtils'
import { GlobalActivityAlertTiles, GlobalActivityGraph } from './globalActivity'

const GlobalActivityPage = () => {
	const intl = useIntl()
	const { enqueueSnackbar } = useSnackbar()
	const [alertTiles, setAlertTiles] = useState<GlobalActivityAlertTiles | undefined>(undefined)
	const [graph, setGraph] = useState<GlobalActivityGraph | undefined>(undefined)
	const [submittedFilters, setSubmittedFilters] = useState<FiltersFormValues | undefined>(undefined)
	const [exportPreviousWeek, setExportPreviousWeek] = useState(false)

	const onSubmit = (values: FiltersFormValues) => getGlobalActivityData(values.periodType, values.period)

	const getGlobalActivityData = (periodType: DATA_TYPE, period: string) => {
		setAlertTiles(undefined)
		setGraph(undefined)
		setSubmittedFilters({
			periodType,
			period,
			selectReferential: undefined
		})
		return Promise
			.all([
				getActivityAlertTiles(API_CALL_ACTIVITY_KEY.GLOBAL, periodType, period),
				getActivityGraphs(API_CALL_ACTIVITY_KEY.GLOBAL, periodType, period)
			])
			.then(result => {
				setAlertTiles(result[0])
				setGraph(result[1])
			})
	}

	const updatedDateAlertTiles = useMemo(() => alertTiles ? intl.formatDate(alertTiles.updateDate) : '', [alertTiles, intl])
	const previousDate = useMemo(() => {
		if (alertTiles) {
			if (alertTiles.periodType === DATA_TYPE.WEEK) {
				return intl.formatDate(getMondayOfLastWeek(new Date(alertTiles.period)))
			}
			return getLastYear(new Date(alertTiles.period))
		}
		return new Date()
	}, [alertTiles, intl])

	const exportIndicatorIpvgeno = useCallback(() => {
		if (alertTiles) {
			getGlobalExportFile(GLOBAL_CONTENT_TYPE.IPVGENO, alertTiles.periodType, exportPreviousWeek)
				.catch(() => enqueueSnackbar(intl.formatMessage({ id: 'global.errors.occured', defaultMessage: 'Error occured', description: 'error' }), { variant: SNACK_VARIANT.ERROR }))
		}
	}, [alertTiles, enqueueSnackbar, exportPreviousWeek, intl])

	const exportIndicatorEchantillonErreur = useCallback(() => {
		if (alertTiles) {
			getGlobalExportFile(GLOBAL_CONTENT_TYPE.ECHANTILLON, alertTiles.periodType, exportPreviousWeek)
				.catch(() => enqueueSnackbar(intl.formatMessage({ id: 'global.errors.occured', defaultMessage: 'Error occured', description: 'error' }), { variant: SNACK_VARIANT.ERROR }))
		}
	}, [alertTiles, enqueueSnackbar, exportPreviousWeek, intl])

	const exportStatus = useDisableExport(alertTiles, setExportPreviousWeek)

	return <>
		<Typography variant="h1"><FormattedMessage id="activity.global.title" defaultMessage="Global activity"
		                                           description="Global activity title" /></Typography>
		<FiltersContainer
			onSubmit={onSubmit}
			target="global"
		>
			{(!alertTiles && !graph) && <EmptyState filters={submittedFilters} />}

			{alertTiles && <Grid item xs={12} container sx={{ mt: '16px' }}>
				<Indicator
					title={<FormattedMessage id="activity.global.indicators.ipvGeno.title" defaultMessage="IPVgeno"
					                         description="Indicator ipvGeno title" />}
					count={alertTiles.nbIpvgenos}
					evolution={alertTiles.indicatorNbIpvgenos}
					description={<FormattedMessage id="activity.global.indicators.ipvGeno.description"
					                               defaultMessage="IPVgeno description"
					                               description="Indicator ipvGeno description"
					                               values={{ periodType: alertTiles.periodType, date: previousDate }} />}
					tooltip={intl.formatMessage({
						id: 'activity.global.indicators.ipvGeno.tooltip',
						defaultMessage: 'IPVgeno tooltip',
						description: 'Indicator ipvGeno tooltip'
					})}
					updateDate={updatedDateAlertTiles}
					exportData={exportIndicatorIpvgeno}
					disableExport={exportStatus}
				/>
				<Indicator
					title={<FormattedMessage id="activity.global.indicators.error.title" defaultMessage="Error"
					                         description="Indicator error title" />}
					count={alertTiles.nbErrors}
					evolution={alertTiles.indicatorNbErrors}
					description={<FormattedMessage id="activity.global.indicators.error.description"
					                               defaultMessage="Error description"
					                               description="Indicator error description" />}
					tooltip={intl.formatMessage({
						id: 'activity.global.indicators.error.tooltip',
						defaultMessage: 'Error tooltip',
						description: 'Indicator error tooltip'
					})}
					updateDate={updatedDateAlertTiles}
					exportData={exportIndicatorEchantillonErreur}
					disableExport={exportStatus}
					isRatio={true}
				/>
			</Grid>}

			{graph && <Grid item xs={12} container sx={{ mt: '16px' }}>
				<Samples
					data={graph.graphSamplesReceivedAndAnalysed.data}
					updatedDate={updatedDateAlertTiles}
				/>
				<TypesByLaboratory
					data={graph.graphTyping.data}
					updatedDate={updatedDateAlertTiles}
				/>
			</Grid>}
		</FiltersContainer>
		<ProcessButton />
	</>
}

export default GlobalActivityPage
