import React, { useContext } from 'react'
import { Grid, Typography } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import { FieldErrors, Resolver, useForm } from 'react-hook-form'
import { AppContext } from '../appData/AppContext'
import { AppModel } from '../appData/appModel'
import FormInput from '../../components/form/FormInput'
import { SubmitButton } from '../../components/button/button'
import FormPassword from '../../components/form/FormPassword'
import { isValidPassword } from '../../utils/formUtils'
import { updatePasswordFromProfile, updatePersonalData } from '../../modules/profile/services/profileApi'
import { SNACK_VARIANT } from '../../utils/constants'
import { useSnackbar } from 'notistack'
import { StyledSection, StyledSectionTitle } from '../../components/layout/Section.styles'

const personalDataResolver: Resolver<PersonalDataForm> = async (values) => {
	const errors: FieldErrors<PersonalDataForm> = {}

	if (!values.firstname) {
		errors.firstname = { type: 'required', message: 'global.errors.required' }
	}
	if (!values.lastname) {
		errors.lastname = { type: 'required', message: 'global.errors.required' }
	}
	return { values, errors }
}

const updatePasswordResolver: Resolver<UpdatePasswordProfileForm> = async (values) => {
	const errors: FieldErrors<UpdatePasswordProfileForm> = {}

	if (!values.current) {
		errors.current = { type: 'required', message: 'global.errors.required' }
	}
	if (!values.new) {
		errors.new = { type: 'required', message: 'global.errors.required' }
	} else if (!isValidPassword(values.new)) {
		errors.new = { type: 'format', message: 'global.errors.format' }
	}
	if (!values.repeated) {
		errors.repeated = { type: 'required', message: 'global.errors.required' }
	} else if (!isValidPassword(values.repeated)) {
		errors.repeated = { type: 'format', message: 'global.errors.format' }
	}

	if (values.new && values.repeated && values.new !== values.repeated) {
		errors.repeated = { type: 'match', message: 'updatePassword.errorMatch' }
	}
	return { values, errors }
}

const ProfilePage = () => {
	const { user, updateUser } = useContext<AppModel>(AppContext)
	const { enqueueSnackbar } = useSnackbar()
	const intl = useIntl()
	const personalDataForm = useForm<PersonalDataForm>({
		mode: 'onSubmit',
		defaultValues: {
			firstname: user?.firstname,
			lastname: user?.lastname,
			email: user?.email
		},
		resolver: personalDataResolver
	})
	const updatePasswordForm = useForm<UpdatePasswordProfileForm>({
		mode: 'onSubmit',
		defaultValues: {
			current: '',
			new: '',
			repeated: ''
		},
		resolver: updatePasswordResolver
	})

	const submitPersonalData = (values: PersonalDataForm) => {
		return updatePersonalData(values)
			.then(res => {
				updateUser(state => ({ ...(state as User), firstname: res.firstname, lastname: res.lastname }))
				enqueueSnackbar(intl.formatMessage({ id: 'profile.success', defaultMessage: 'Update personal data request succeed', description: 'submit success' }), { variant: SNACK_VARIANT.SUCCESS })
			})
			.catch(() => enqueueSnackbar(intl.formatMessage({ id: 'global.errors.occured', defaultMessage: 'Error occured', description: 'error' }), { variant: SNACK_VARIANT.ERROR }))
	}

	const submitUpdatePassword = (values: UpdatePasswordProfileForm) => {
		return updatePasswordFromProfile(values)
			.then(() => {
				updatePasswordForm.reset()
				enqueueSnackbar(intl.formatMessage({ id: 'profile.success', defaultMessage: 'Update password request succeed', description: 'submit success' }), { variant: SNACK_VARIANT.SUCCESS })
			})
			.catch(e => {
				console.log('e', e)
				// Password error handler
				if (e && (e.oldPassword || e.password)) {
					if (e.oldPassword) {
						updatePasswordForm.setError('current', { type: 'password-error', message: 'global.errors.password.oldPasswordFailed' })
					} else if (e.password) {
						updatePasswordForm.setError('new', { type: 'password-error', message: `global.errors.password.${e.password.id.replace('password.errors.', '')}` })
					}
					enqueueSnackbar(intl.formatMessage({ id: 'global.errors.formWithErrors', defaultMessage: 'Form with errors', description: 'form error' }), { variant: SNACK_VARIANT.ERROR })
				} else {
					enqueueSnackbar(intl.formatMessage({ id: 'global.errors.occured', defaultMessage: 'Error occured', description: 'error' }), { variant: SNACK_VARIANT.ERROR })
				}
			})
	}

	return <>
		<Typography variant="h1"><FormattedMessage id="profile.account" defaultMessage="My account" description="Account title" /></Typography>
		<Grid item xs={12} container spacing={2}>
			<Grid item xs={6}>
				<StyledSection>
					<form onSubmit={personalDataForm.handleSubmit(submitPersonalData)}>
						<StyledSectionTitle variant="h2"><FormattedMessage id="profile.personalData.title" defaultMessage="My personal data" description="Personal data section title" /></StyledSectionTitle>
						<Grid item xs={12}>
							<FormInput<PersonalDataForm>
								id="firstname"
								name="firstname"
								label={intl.formatMessage({ id: 'global.user.firstname', defaultMessage: 'Firstname', description: 'firstname input' })}
								required
								control={personalDataForm.control}
							/>
						</Grid>
						<Grid item xs={12}>
							<FormInput<PersonalDataForm>
								id="lastname"
								name="lastname"
								label={intl.formatMessage({ id: 'global.user.lastname', defaultMessage: 'Lastname', description: 'lastname input' })}
								required
								control={personalDataForm.control}
							/>
						</Grid>
						<Grid item xs={12} sx={{ mb: '35px' }}>
							<FormInput<PersonalDataForm>
								id="email"
								name="email"
								label={intl.formatMessage({ id: 'global.user.email', defaultMessage: 'Email', description: 'email input' })}
								required
								disabled
								control={personalDataForm.control}
							/>
						</Grid>
						<Grid item xs={12} container justifyContent="center" sx={{ mb: '130px' }}>
							<SubmitButton
								label="global.buttons.save"
								loading={personalDataForm.formState.isSubmitting}
							/>
						</Grid>
					</form>
				</StyledSection>
			</Grid>
			<Grid item xs={6}>
				<StyledSection>
					<form onSubmit={updatePasswordForm.handleSubmit(submitUpdatePassword)}>
						<Typography variant="h2" sx={{ mb: '32px' }}><FormattedMessage id="profile.updatePassword.title" defaultMessage="Update my password" description="Update password section title" /></Typography>
						<Grid item xs={12} sx={{ mb: '31px', whiteSpace: 'pre-line' }}><Typography variant="caption"><FormattedMessage id="updatePassword.caption" defaultMessage="Init password caption"
						                                                                                                                                        description="init password caption" /></Typography></Grid>
						<Grid item xs={12}>
							<FormPassword<UpdatePasswordProfileForm>
								id="current"
								name="current"
								label={intl.formatMessage({ id: 'profile.updatePassword.currentPassword', defaultMessage: 'Current password', description: 'current password input' })}
								control={updatePasswordForm.control}
							/>
						</Grid>
						<Grid item xs={12}>
							<FormPassword<UpdatePasswordProfileForm>
								id="new"
								name="new"
								label={intl.formatMessage({ id: 'profile.updatePassword.newPassword', defaultMessage: 'New password', description: 'new password input' })}
								control={updatePasswordForm.control}
							/>
						</Grid>
						<Grid item xs={12} sx={{ mb: '35px' }}>
							<FormPassword<UpdatePasswordProfileForm>
								id="repeated"
								name="repeated"
								label={intl.formatMessage({ id: 'profile.updatePassword.repeatedPassword', defaultMessage: 'Repeated password', description: 'repeated password input' })}
								control={updatePasswordForm.control}
							/>
						</Grid>
						<Grid item xs={12} container justifyContent="center">
							<SubmitButton
								label="global.buttons.update"
								loading={updatePasswordForm.formState.isSubmitting}
							/>
						</Grid>
					</form>
				</StyledSection>
			</Grid>
		</Grid>

	</>
}

export default ProfilePage
