import flatten from 'flat'

const fr = {
	menu: {
		dashboard: 'Tableau de bord',
		activity: 'Activité',
		global: 'Globale',
		byLaboratory: 'Par laboratoire',
		byClient: 'Par client',
		users: 'Utilisateurs'
	},
	global: {
		errors: {
			required: 'Champ obligatoire',
			format: 'Format incorrect',
			occured: 'Une erreur est survenue',
			notUniqueEmail: 'Email déjà utilisé',
			formWithErrors: 'Votre formulaire comporte des erreurs',
			password: {
				oldPasswordFailed: 'Mot de passe incorrect',
				newPasswordContainsOld: 'Le mot de passe ne peut pas contenir l\'ancien',
				containsInfo: 'Le mot de passe ne peut pas contenir vos informations personnelles',
			},
			noFileFound: 'Aucun fichier trouvé pour procéder à la mise à jour'
		},
		buttons: {
			connect: 'Me connecter',
			reset: 'Réinitialiser',
			back: 'Retour',
			validate: 'Valider',
			save: 'Enregistrer',
			update: 'Mettre à jour',
			cancel: 'Annuler',
			process: 'Relancer les calculs'
		},
		user: {
			firstname: 'Prénom',
			lastname: 'Nom',
			email: 'Email',
			role: 'Droit',
			status: 'Statut'
		},
		charts: {
			success: 'Les calculs ont été relancé avec succès',
			updatedDate: 'Données mises à jour le {date}',
			export: {
				indicator: {
					button: 'Exporter les données'
				},
				button: 'Exporter sur Excel',
				headers: {
					laboratory: 'Laboratoire',
					count: 'Nombre de typages',
					month: 'Mois',
					breed: 'Race',
					rowData: 'Données',
					microchip: 'Type de puce'
				},
				filename: {
					ipvGenoTotal: 'Nb IPVgénos depuis 01 janvier',
					ipvGenoByBreed: 'Nb IPVgénos par race depuis 01 janvier',
					typesByLaboratory: 'Nb typages par labo',
					byMicrochipType: '{laboratory}_Répartition par type de puce',
					clientTypesByLaboratory: '{client}_Typages par laboratoire',
					typesByBreed: '{client}_Typages par race'
				}
			}
		},
		noProcessedFile: '<p>Le fichier de suivi n’étant pas disponible, les indicateurs n’ont pas pu être calculés...{br}' +
			'Vous pouvez lancer le calcul des indicateurs manuellement en utilisant le bouton Mettre à jour.</p>',
		noData: '<p>Aucune donnée à afficher pour la période sélectionnée {br}</p>'
	},
	enums: {
		role: {
			MEMBER: 'Membre',
			ADMIN: 'Administrateur'
		},
		status: {
			enabled: 'Actif',
			disabled: 'Désactivé',
			guest: 'Invité'
		},
		dataType : {
			WEEK: 'Données hebdomadaires',
			MONTH: 'Données mensuelles',
			YEAR: 'Données annuelles'
		},
		month: {
			1: 'Janvier',
			2: 'Février',
			3: 'Mars',
			4: 'Avril',
			5: 'Mai',
			6: 'Juin',
			7: 'Juillet',
			8: 'Août',
			9: 'Septembre',
			10: 'Octobre',
			11: 'Novembre',
			12: 'Décembre',
		},
		breed: {
			PRIM_HOLSTEIN: 'Prim\'Holstein',
			NORMANDE: 'Normande',
			MONTBELIARDE: 'Montbéliarde',
			INTERNATIONAL: 'Race Internationale',
			MASSIFS: 'Race des Massifs',
			BREASTFEEDING_WITHOUT_IPVGENO: 'Races allaitantes sans ipvgénos',
			BREASTFEEDING: 'Grandes races allaitantes',
			MIXED: 'Croisées',
			RACE_PREFIX: 'Race {breed}'
		},
		laboratory: {
			LABOGENA: 'Labogena',
			GDIPL: 'GD IPL',
			AGRANIS: 'AGRANIS',
			AVEYRON_LABO: 'AVEYRON-LABO',
			ETRANGERS: 'Etrangers'
		},
		sex: {
			MALE: 'Mâles',
			FEMALE: 'Femelles',
			EMBRYO: 'Embryons'
		}
	},
	profile: {
		account: 'Mon compte',
		logout: 'Déconnexion',
		personalData: {
			title: 'Mes données personnelles'
		},
		updatePassword: {
			title: 'Modifier mon mot de passe',
			currentPassword: 'Mot de passe actuel',
			newPassword: 'Nouveau mot de passe',
			repeatedPassword: 'Confirmation de nouveau mot de passe',
		},
		success: 'Vos données ont été enregistrées avec succès.'
	},
	login: {
		title: 'Bienvenue !',
		description: 'Bienvenue dans votre espace personnel, veuillez renseigner vos identifiants et votre mot de passe.',
		link: 'Mot de passe oublié ?',
		form: {
			email: 'Email',
			password: 'Mot de passe'
		},
		error: 'Email ou mot de passe incorrect'
	},
	passwordForgotten: {
		title: 'Mot de passe oublié ?',
		description: 'Veuillez renseigner votre adresse email de compte afin de recevoir un lien de réinitialisation de votre mot de passe.',
		success: 'Un email de réinitialisation a été envoyé à l’adresse email saisie. Si vous ne recevez pas le mail, vérifiez l’adresse email saisie.'
	},
	updatePassword: {
		title: 'Réinitialisation de votre mot de passe',
		titleInit: 'Initialisation du mot de passe',
		description: 'Suite à votre demande de réinitialisation de mot de passe, veuillez saisir un nouveau mot de passe de connexion.',
		descriptionInit: 'Afin d’activer votre compte, veuillez initialiser votre mot de passe de connexion.',
		caption: 'Votre mot de passe doit comporter au moins 8 caractères avec au moins : \n' +
			'• Une majuscule\n' +
			'• Une minuscule (les accents ne sont pas autorisés)\n' +
			'• Un chiffre\n' +
			'• Un caractère spécial parmi : #, ?, !, @, $, &, *, -',
		form: {
			password: 'Nouveau mot de passe',
			passwordInit: 'Mot de passe',
			repeatedPassword: 'Confirmation de mot de passe'
		},
		success: 'Votre mot de passe a bien été modifié',
		successInit: 'Votre mot de passe a bien été initialisé',
		errorMatch: 'Le mot de passe et la confirmation ne sont pas identiques',
		invalidToken: 'Le lien de réinitialisation du mot de passe n\'est plus valide'
	},
	users: {
		title: 'Liste des utilisateurs',
		search: 'Rechercher',
		add: 'Ajouter un utilisateur',
		addSuccess: 'L\'utilisateur a bien été créé',
		noData: 'Aucun utilisateur ne correspond à votre recherche',
		enableAccount: {
			title: 'Confirmation de réactivation',
			description: 'Êtes-vous sûr de vouloir réactiver le compte de cet utilisateur ?',
			confirm: 'Activer',
			success: 'L\'utilisateur a bien été activé'
		},
		disableAccount: {
			title: 'Confirmation de désactivation',
			description: 'Si vous désactivez le compte, cet utilisateur ne pourra plus se connecter. Êtes-vous sûr de vouloir désactiver ce compte ?',
			confirm: 'Désactiver',
			success: 'L\'utilisateur a bien été désactivé'
		},
		guestNotification: {
			title: 'Renvoi de l’email d’initialisation de mot de passe',
			description: 'Êtes-vous sûr de vouloir renvoyer le mail d’activation de compte à cet utilisateur ?',
			confirm: 'Envoyer',
			success: 'L\'email a bien été envoyé'
		}
	},
	dashboard: {
		alertTiles: {
			ipvGeno: 'Nouveau{nbIpvGeno, plural, zero {} one {} other {x}} IPVgéno pour la semaine du {date}',
			animals: '{nbAnimals, plural, zero {Animal} one {Animal} other {Animaux}} en erreur sur la semaine du {date}'
		},
		indicators: {
			sample: {
				title: 'Nombre d’échantillons total',
				tooltip: 'Nombre d’échantillons bovins enregistrés chez Valogène depuis sa création',
				description: 'échantillon{nbSamples, plural, zero {} one {} other {s}} traité{nbSamples, plural, zero {} one {} other {s}} par Valogène depuis 2010',
			},
			ipvGeno: {
				title: 'IPVgénos diffusés depuis le 1er janvier {year}',
				tooltip: 'Nombre d’IPVgénos cumulés depuis le début de l’année',
				description: 'IPVgéno{nbIpvGeno, plural, zero {} one {} other {s}} diffusé{nbIpvGeno, plural, zero {} one {} other {s}} par Valogène depuis le 1er janvier {year}'
			}
		},
		charts: {
			ipvGenoTotal: {
				title: 'Nombre d’IPVgénos total depuis le 1er janvier {year}',
				tooltip: 'Graphique de la répartition mensuelle des IPVgénos depuis le début de l’année',
				yearSerie: 'Année {year}'
			},
			ipvGenoByBreed: {
				title: 'Nombre d’IPVgénos par race depuis le 1er janvier {year}',
				tooltip: 'Graphique de la répartition par race du nombre d’IPVgénos depuis le début de l’année en cours'
			}
		}
	},
	activity: {
		filters : {
			dataType: 'Type de données',
			period : {
				week : '\'Semaine du\' dd/MM/yy'
			}
		},
		global: {
			title: 'Activité globale bovine',
			indicators: {
				ipvGeno: {
					title: 'Nombre de nouveaux IPVgénos diffusés',
					tooltip: 'Nombre de nouveaux IPVgénos diffusés sur la période choisie',
					description: 'Par rapport à la {periodType, select, WEEK {semaine du {date}} other {même période en {date}}}'
				},
				error: {
					title: 'Nombre d’échantillons en erreur',
					tooltip: 'Échantillons en erreur (labo ou client), ce qui ne permet pas de diffuser leurs IPVgénos',
					description: 'Du nombre d\'animaux analysés'
				}
			},
			charts: {
				samples: {
					title: 'Nombre d’échantillons reçus et d’échantillons analysés',
					tooltip: 'Nombre d’échantillons reçus au laboratoire et nombre d’échantillons analysés',
					headers: {
						laboratory: 'Laboratoire',
						nbSamples: 'Nb d\'échantillons',
						nbTypes: 'Nb de typages',
						alert: 'Alerte'
					}
				},
				typesByLaboratory: {
					title: 'Nombre de typages par laboratoire',
					tooltip: 'Nombre de résultats de génotypage rendus par le laboratoire'
				},
			}
		},
		byLaboratory: {
			title: 'Activité bovine par laboratoire',
			indicators: {
				totalTypes: {
					title: 'Nombre de typages',
					tooltip: 'Nombre d’échantillons ayant obtenus un résultat dans la période donnée',
					description: 'Par rapport à la {periodType, select, WEEK {semaine du {date}} other {même période en {date}}}'
				},
				errorTypes: {
					title: 'Nombre de typages en anomalie',
					tooltip: 'Nombre d’échantillons n’ayant pas de résultats exploitables après analyse',
					description: 'Du nombre de typages total'
				},
				delayedSamples: {
					title: 'Nombre d’échantillons en retard',
					tooltip: 'Nombre d’échantillons au laboratoire n’ayant pas eu de résultat de génotypage'
				}
			},
			charts: {
				byMicrochipType: {
					title: 'Répartition par type de puce',
					tooltip: 'Proportion de l’utilisation des types de puces au sein du laboratoire'
				},
				byMicrochipVersion: {
					title: 'Répartition par version de puce EuroGMD',
					tooltip: 'Nombre de typages réalisés sur les différentes versions de puces Eurogmd',
					label: 'Puces {version}'
				}
			}
		},
		byClient: {
			title: 'Activité bovine par client',
			indicators: {
				ipvGeno: {
					title: 'Nombre de nouveaux IPVgénos',
					tooltip: 'Nombre d’animaux ayant obtenus des IPVgénos sur la période donnée',
					description: 'Par rapport à la {periodType, select, WEEK {semaine du {date}} other {même période en {date}}}'
				},
				animalErrors: {
					title: 'Nombre d\'animaux en erreur',
					tooltip: 'Nombre d’animaux n’ayant pas obtenus d’IPVgénos',
					description: 'Du nombre d\'animaux analysés'
				}
			},
			charts: {
				typesByLaboratory: {
					title: 'Nombre de typages par laboratoire',
					tooltip: 'Répartition des échantillons selon le laboratoire d’analyse'
				},
				typesBySexe: {
					title: 'Nombre de typages par sexe',
					tooltip: 'Répartition des typages selon le sexe des animaux'
				},
				typesByBreed: {
					title: 'Nombre de typages par race',
					tooltip: 'Répartition des typages selon la race de l’animal',
					headers: {
						race: 'Races',
						type: 'Nb de typages'
					}
				}
			}
		}
	}
}

export default flatten<typeof fr, ModelTranslations>(fr)
