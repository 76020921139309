import React, { useState } from 'react'
import { Control, FieldPath, FieldValues } from 'react-hook-form'
import { IconButton, InputAdornment } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import FormInput from './FormInput'

type FormPasswordProps<TFieldValues extends FieldValues = FieldValues> = {
	id: string
	label: string
	control: Control<TFieldValues>
	name: FieldPath<TFieldValues>
}

const FormPassword = <TFieldValues extends FieldValues = FieldValues>({
	                                                                      id,
	                                                                      label,
	                                                                      control,
	                                                                      name
                                                                      }: FormPasswordProps<TFieldValues>) => {
	const [showPassword, setShowPassword] = useState<boolean>(false)

	return (<FormInput<TFieldValues>
			id={id}
			type={showPassword ? 'text' : 'password'}
			name={name}
			label={label}
			required
			control={control}
			endAdornment={<InputAdornment position="end">
				<IconButton
					aria-label="toggle password visibility"
					onClick={() => setShowPassword(state => !state)}
					edge="end"
				>
					{showPassword ? <VisibilityOff /> : <Visibility />}
				</IconButton>
			</InputAdornment>}
		/>
	)
}

export default FormPassword
