import React, { useLayoutEffect, useMemo } from 'react'
import ChartContainer from '../../../../components/charts/ChartContainer'
import { FormattedMessage, useIntl } from 'react-intl'
import * as am5 from '@amcharts/amcharts5'
import * as am5percent from '@amcharts/amcharts5/percent'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import { EXPORT_OPTIONS, LABORATORIES } from '../../../../utils/constants'
import { ClientActivityLab } from '../activityByClient'
import * as am5plugins_exporting from '@amcharts/amcharts5/plugins/exporting'
import { Exporting } from '@amcharts/amcharts5/plugins/exporting'
import { getFilenameWithDate } from '../../../../utils/dateUtils'

type TypesByLaboratoryProps = {
	data: ClientActivityLab[]
	client: string
	updateDate: string
}

const CHART_NAME = 'chart-typesByLaboratory'
let exporting: Exporting | undefined = undefined

const TypesByLaboratory: React.FC<TypesByLaboratoryProps> = ({ data, client, updateDate }) => {
	const intl = useIntl()

	// Sort by laboratory name
	// Translated laboratory name
	const formattedData = useMemo(() => (
		[...data].sort((a, b) => ((LABORATORIES[a.laboratory as keyof typeof LABORATORIES] || {}).order || 10) -
			((LABORATORIES[b.laboratory as keyof typeof LABORATORIES] || {}).order || 10))
			.map(dataValue => ({
				...dataValue,
				laboratory: intl.formatMessage({ id: `enums.laboratory.${dataValue.laboratory}`, defaultMessage: dataValue.laboratory, description: 'Laboratory name' })
			}))
	), [data, intl])

	useLayoutEffect(() => {
		const root = am5.Root.new(CHART_NAME)
		root.setThemes([am5themes_Animated.new(root)])
		root.numberFormatter.set('numberFormat', '#')

		exporting = am5plugins_exporting.Exporting.new(root, {
			dataSource: formattedData,
			...EXPORT_OPTIONS,
			filePrefix: getFilenameWithDate(intl.formatMessage({ id: 'global.charts.export.filename.clientTypesByLaboratory', defaultMessage: CHART_NAME, description: 'Chart name' }, { client })),
			dataFields: {
				value: intl.formatMessage({ id: 'global.charts.export.headers.count', defaultMessage: 'Count', description: 'Count header' }),
				laboratory: intl.formatMessage({ id: 'global.charts.export.headers.laboratory', defaultMessage: 'Laboratory', description: 'Laboratory header' })
			},
			dataFieldsOrder: ['laboratory', 'value']
		})

		const chart = root.container.children.push(am5percent.PieChart.new(root, {
			layout: root.verticalLayout,
			innerRadius: am5.percent(50),
			reverseChildren: true
		}))

		// Series
		const series = chart.series.push(am5percent.PieSeries.new(root, {
			valueField: 'value',
			categoryField: 'laboratory',
			alignLabels: false,
			legendLabelText: '{category}',
			legendValueText: ''
		}))
		series.labels.template.setAll({
			textType: 'circular',
			centerX: 0,
			centerY: 0,
			text: '{value}'
		})
		series.data.setAll(formattedData)

		// Tooltips
		series.slices.template.set('tooltipText', '[bold]{category}:[/] {value}')

		// Legend
		const legend = chart.children.push(
			am5.Legend.new(root, {
				centerX: am5.p50,
				x: am5.p50,
				y: 0
			})
		)
		legend.data.setAll(series.dataItems)

		series.appear(1000, 100)

		return () => root.dispose()
	}, [formattedData, client, intl])

	return <ChartContainer
		title={<FormattedMessage id="activity.byClient.charts.typesByLaboratory.title"
		                         defaultMessage="Types by laboratory" description="Chart types by laboratory title" />}
		tooltip={intl.formatMessage({
			id: 'activity.byClient.charts.typesByLaboratory.tooltip',
			defaultMessage: 'Types by laboratory type',
			description: 'Chart types by laboratory tooltip'
		})}
		updatedDate={updateDate}
		exportData={() => exporting?.download('xlsx')}
		xsWidth={6}
	>
		<div id="chart-typesByLaboratory" style={{ width: '100%', height: 400 }} />
	</ChartContainer>
}

export default TypesByLaboratory
