import { Grid, styled, Typography } from '@mui/material'
import loginBackground from '../../assets/img/loginBackground.png'
import { Link } from 'react-router-dom'

export const StyledLoginContainer = styled('form')({
	position: 'absolute',
	left: '3.5vw',
	right: '3.5vw',
	top: '6vh',
	bottom: '6vh',
	background: '#FFFFFF',
	boxShadow: '8px 10px 10px rgba(0, 0, 0, 0.15)',
	borderRadius: 24
})

export const StyledLoginImg = styled(Grid)({
	background: `url(${loginBackground})`,
	backgroundSize: 'cover',
	borderRadius: 24
})

export const StyledLoginForm = styled(Grid)({
	marginTop: '12vh',
	paddingLeft: 52,
	paddingRight: 48
})

export const StyledLoginTitle = styled(Typography)({
	color: '#133864',
	fontFamily: 'Oswald',
	fontStyle: 'normal',
	fontWeight: 700,
	fontSize: 36,
	marginBottom: 24
})

export const StyledLoginLink = styled(Link)({
	fontFamily: 'Lato',
	fontStyle: 'normal',
	fontWeight: 400,
	fontSize: 12,
	color: '#133864',
	textDecoration: 'none'
})

export const StyledErrorToken = styled(Typography)({
	color: '#FF0000'
})
