import React, { useLayoutEffect, useMemo } from 'react'
import ChartContainer from '../../../components/charts/ChartContainer'
import { FormattedMessage, useIntl } from 'react-intl'
import * as am5 from '@amcharts/amcharts5'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import * as am5xy from '@amcharts/amcharts5/xy'
import { BREEDS, CURRENT_YEAR, EXPORT_OPTIONS } from '../../../utils/constants'
import { formatDataIpvgenoByBreed } from '../services/dashboardUtils'
import * as am5plugins_exporting from '@amcharts/amcharts5/plugins/exporting'
import { Exporting } from '@amcharts/amcharts5/plugins/exporting'
import { getFilenameWithDate } from '../../../utils/dateUtils'

type IpvGenoByBreedProps = {
	data: DataIpvgenosByBreed[]
	updatedDate: string
}

const CHART_NAME = 'chart-ipvGenoByBreed'
let exporting: Exporting | undefined = undefined

const IpvGenoByBreed: React.FC<IpvGenoByBreedProps> = ({ data, updatedDate }) => {
	const intl = useIntl()
	const formattedData = useMemo(() => formatDataIpvgenoByBreed(data, intl), [data, intl])

	useLayoutEffect(() => {
		const root = am5.Root.new(CHART_NAME)
		root.setThemes([am5themes_Animated.new(root)])
		root.numberFormatter.set('numberFormat', '#')

		exporting = am5plugins_exporting.Exporting.new(root, {
			dataSource: formattedData,
			...EXPORT_OPTIONS,
			filePrefix: getFilenameWithDate(intl.formatMessage({ id: 'global.charts.export.filename.ipvGenoByBreed', defaultMessage: CHART_NAME, description: 'Chart name' })),
			dataFields: {
				month: intl.formatMessage({ id: 'global.charts.export.headers.month', defaultMessage: 'Month', description: 'Month header' }),
				...(Object.values(BREEDS).reduce((acc, curr) => ({
					...acc,
					[curr.code]: intl.formatMessage({ id: `enums.breed.${curr.code}`, defaultMessage: curr.code, description: 'Breed name' })
				}), {}))
			},
			dataFieldsOrder: ['month', ...Object.values(BREEDS).map(b => b.code)]
		})

		const chart = root.container.children.push(am5xy.XYChart.new(root, {
			panX: false,
			panY: false,
			wheelX: 'none',
			wheelY: 'none',
			layout: root.verticalLayout
		}))

		const cursor = chart.set('cursor', am5xy.XYCursor.new(root, {
			behavior: 'none'
		}))
		cursor.lineX.set('visible', false)
		cursor.lineY.set('visible', false)

		// Legend
		const legend = chart.children.push(am5.Legend.new(root, {}))

		// Axis
		const xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
			categoryField: 'month',
			startLocation: 0.47,
			endLocation: 0.5,
			renderer: am5xy.AxisRendererX.new(root, {})
		}))
		xAxis.data.setAll(formattedData)

		const yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
			visible: false,
			renderer: am5xy.AxisRendererY.new(root, {})
		}))

		// Series
		const makeSeries = (name: string, color: string) => {
			const series = chart.series.push(am5xy.LineSeries.new(root, {
				name: intl.formatMessage({ id: `enums.breed.${name}`, defaultMessage: name, description: 'Breed name' }),
				xAxis: xAxis,
				yAxis: yAxis,
				stacked: true,
				valueYField: name,
				categoryXField: 'month'
			}))

			series.fills.template.setAll({
				fillOpacity: 1,
				visible: true
			})

			series.set('fill', am5.color(color))

			series.data.setAll(formattedData)
			series.appear(1000)
			legend.data.push(series)
		}

		Object.values(BREEDS).forEach(breed => makeSeries(breed.code, breed.color))

		chart.appear(1000, 100)

		return () => root.dispose()
	}, [formattedData, intl])


	return <ChartContainer
		title={<FormattedMessage id="dashboard.charts.ipvGenoByBreed.title" defaultMessage="IPVgeno by breed" description="Chart ipvGeno by breed title" values={{ year: CURRENT_YEAR }} />}
		tooltip={intl.formatMessage({ id: 'dashboard.charts.ipvGenoByBreed.tooltip', defaultMessage: 'IPVgeno tooltip', description: 'Chart IPVgeno tooltip' })}
		updatedDate={updatedDate}
		exportData={() => exporting?.download('xlsx')}
	>
		<div id={CHART_NAME} style={{width: '100%', height: 400 }}></div>
	</ChartContainer>
}

export default IpvGenoByBreed
