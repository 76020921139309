import React from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { Close as CloseIcon } from '@mui/icons-material'
import { PrimaryButton, SecondaryButton } from '../button/button'

type ConfirmDialogProps = {
	open: boolean
	title: string
	description: string
	confirmLabel: string
	confirmLoading: boolean
	confirmClick: () => void
	onClose: () => void
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
	                                                     open,
	                                                     title,
	                                                     description,
	                                                     confirmLabel,
	                                                     confirmLoading,
	                                                     confirmClick,
	                                                     onClose
                                                     }) => {
	return <Dialog open={open} onClose={onClose} aria-labelledby="dialog" fullWidth>
		<DialogTitle sx={{ mb: '11px', pt: '20px', pX: '20px' }}>
			<Typography variant="h2"><FormattedMessage id={title} defaultMessage={title} description="Dialog title" /></Typography>
			{onClose && (
				<IconButton
					aria-label="close"
					onClick={onClose}
					sx={{
						position: 'absolute',
						right: 8,
						top: 12
					}}
				>
					<CloseIcon />
				</IconButton>
			)}
		</DialogTitle>
		<DialogContent sx={{ pt: '5px !important', pX: '20px' }}>
			<Typography variant="body2"><FormattedMessage id={description} defaultMessage={description} description="Dialog description" /></Typography>
		</DialogContent>
		<DialogActions sx={{ paddingX: '20px', pb: '20px' }}>
			<SecondaryButton
				label="global.buttons.cancel"
				onClick={onClose}
			/>
			<PrimaryButton
				label={confirmLabel}
				loading={confirmLoading}
				onClick={confirmClick}
			/>
		</DialogActions>
	</Dialog>
}

export default ConfirmDialog
