import React from 'react'
import { StyledLoginContainer, StyledLoginForm, StyledLoginImg, StyledLoginTitle } from './Login.styles'
import logo from '../../assets/img/logo.png'
import { Grid, Typography } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router'
import FormInput from '../../components/form/FormInput'
import { FieldErrors, Resolver, useForm } from 'react-hook-form'
import { isValidEmail } from '../../utils/formUtils'
import { useSnackbar } from 'notistack'
import { APP_ROUTES, SNACK_VARIANT } from '../../utils/constants'
import { resetPassword } from '../../modules/login/services/loginApi'
import { SecondaryButton, SubmitButton } from '../../components/button/button'

const resolver: Resolver<PasswordForgottenForm> = async (values: PasswordForgottenForm) => {
	const errors: FieldErrors<PasswordForgottenForm> = {}

	if (!values.email) {
		errors.email = { type: 'required', message: 'global.errors.required' }
	} else if (!isValidEmail(values.email)) {
		errors.email = { type: 'format', message: 'global.errors.format' }
	}
	return { values, errors }
}

const PasswordForgotten = () => {
	const { enqueueSnackbar } = useSnackbar()
	const navigate = useNavigate()
	const intl = useIntl()
	const passwordForgottenForm = useForm<PasswordForgottenForm>({
		mode: 'onSubmit',
		defaultValues: {
			email: ''
		},
		resolver
	})

	const handleSubmit = (values: PasswordForgottenForm) => {
		return resetPassword(values)
			.then(() => enqueueSnackbar(intl.formatMessage({ id: 'passwordForgotten.success', defaultMessage: 'Password forgotten request succeed', description: 'submit success' }), { variant: SNACK_VARIANT.SUCCESS }))
			.catch(() => enqueueSnackbar(intl.formatMessage({ id: 'global.errors.occured', defaultMessage: 'Error occured', description: 'error' }), { variant: SNACK_VARIANT.ERROR }))
	}

	return <StyledLoginContainer onSubmit={passwordForgottenForm.handleSubmit(handleSubmit)}>
		<Grid item xs={12} height="100%" container>

			<StyledLoginImg item xs={6} />
			<StyledLoginForm item xs={6} container>

				<Grid item xs={12} alignItems="flex-start">
					<Grid item xs={12} textAlign="center" sx={{ mb: '6vh' }}><img src={logo} alt={logo} width={182} height={62} /></Grid>
					<Grid item xs={12} textAlign="center">
						<StyledLoginTitle>
							<FormattedMessage id="passwordForgotten.title" defaultMessage="Password forgotten" description="password forgotten title" />
						</StyledLoginTitle>
					</Grid>
					<Grid item xs={12} textAlign="center" sx={{ mb: '32px' }}>
						<Typography variant="body2">
							<FormattedMessage id="passwordForgotten.description" defaultMessage="Password forgotten description" description="password forgotten description" />
						</Typography>
					</Grid>
					<Grid item xs={12} container justifyContent="center">
						<Grid item xs={5}>
							<FormInput<PasswordForgottenForm>
								id="email"
								name="email"
								label={intl.formatMessage({ id: 'login.form.email', defaultMessage: 'Email', description: 'email input' })}
								required
								control={passwordForgottenForm.control}
							/>
						</Grid>
					</Grid>
					<Grid item xs={12} textAlign="center" sx={{ mb: '12px' }}>
						<SecondaryButton
							label="global.buttons.back"
							onClick={() => navigate(APP_ROUTES.LOGIN)}
						/>
						<SubmitButton
							label="global.buttons.reset"
							loading={passwordForgottenForm.formState.isSubmitting}
						/>
					</Grid>
				</Grid>
			</StyledLoginForm>
		</Grid>
	</StyledLoginContainer>
}

export default PasswordForgotten
