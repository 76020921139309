import { fetchFactory } from '../../../utils/fetch'

export const resetPassword = (form: PasswordForgottenForm) => fetchFactory('/forgotten-password', {
	method: 'POST',
	body: JSON.stringify(form)
}, '/api/public')

export const checkTokenValidity = (token: string) => fetchFactory(`/forgotten-password?token=${token}`, {}, '/api/public')

export const updatePassword = (token: string, form: UpdatePasswordForm) => fetchFactory('/forgotten-password', {
	method: 'PUT',
	body: JSON.stringify({
		token,
		password: form.password
	})
}, '/api/public')

