import React, { useCallback, useMemo, useState } from 'react'
import { Grid, Typography } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import Indicator from '../../../components/layout/Indicator'
import DistributionByMicrochipType from './components/DistributionByMicrochipType'
import DistributionByMicrochipVersion from './components/DistributionByMicrochipVersion'
import FiltersContainer from '../../../components/form/filters/FiltersContainer'
import { API_CALL_ACTIVITY_KEY, DATA_TYPE, DEFAULT_VALUE_LABO, FILE_TYPE, LABO_CONTENT_TYPE, REFERENTIAL_TYPE, SNACK_VARIANT } from '../../../utils/constants'
import ProcessButton from '../components/ProcessButton'
import { FiltersFormValues } from '../../../components/form/filters/filters'
import { getActivityAlertTiles, getActivityGraphs, getClientLaboExportFile } from '../services/activityApi'
import EmptyState from '../../../components/layout/EmptyState'
import { LaboActivityAlertTiles, LaboActivityGraphs } from './activityByLaboratory'
import { getLastYear, getMondayOfLastWeek } from '../../../utils/dateUtils'
import { useSnackbar } from 'notistack'
import { useDisableExport } from '../services/activityUtils'

const ActivityByLaboratory = () => {
	const intl = useIntl()
	const { enqueueSnackbar } = useSnackbar()
	const [alertTiles, setAlertTiles] = useState<LaboActivityAlertTiles | undefined>(undefined)
	const [graph, setGraph] = useState<LaboActivityGraphs | undefined>(undefined)
	const [submittedFilters, setSubmittedFilters] = useState<FiltersFormValues | undefined>(undefined)
	const [exportPreviousWeek, setExportPreviousWeek] = useState(false)

	const onSubmit = (values: FiltersFormValues) => getLaboratoryActivityData(values.periodType, values.period, values.selectReferential)

	const getLaboratoryActivityData = (periodType: DATA_TYPE, period: string, selectReferential: string | undefined) => {
		setAlertTiles(undefined)
		setGraph(undefined)
		setSubmittedFilters({
			periodType,
			period,
			selectReferential
		})
		return Promise
			.all([
				getActivityAlertTiles(API_CALL_ACTIVITY_KEY.LABORATORY, periodType, period, selectReferential),
				getActivityGraphs(API_CALL_ACTIVITY_KEY.LABORATORY, periodType, period, selectReferential)
			])
			.then(result => {
				setAlertTiles(result[0])
				setGraph(result[1])
			})
	}

	const updatedDateAlertTiles = useMemo(() => alertTiles ? intl.formatDate(alertTiles.updateDate) : '', [alertTiles, intl])
	const previousDate = useMemo(() => {
		if (alertTiles) {
			if (alertTiles.periodType === DATA_TYPE.WEEK) {
				return intl.formatDate(getMondayOfLastWeek(new Date(alertTiles.period)))
			}
			return getLastYear(new Date(alertTiles.period))
		}
		return new Date()
	}, [alertTiles, intl])

	const exportIndicatorIpvgeno = useCallback(() => {
		if (alertTiles) {
			getClientLaboExportFile(
				FILE_TYPE.LABO,
				LABO_CONTENT_TYPE.TYPAGE,
				alertTiles.periodType, alertTiles.laboratory.name,
				exportPreviousWeek
			).catch(() => enqueueSnackbar(intl.formatMessage({ id: 'global.errors.occured', defaultMessage: 'Error occured', description: 'error' }), { variant: SNACK_VARIANT.ERROR }))
		}
	}, [alertTiles, enqueueSnackbar, exportPreviousWeek, intl])

	const exportIndicatorEchantillonErreur = useCallback(() => {
		if (alertTiles) {
			getClientLaboExportFile(
				FILE_TYPE.LABO,
				LABO_CONTENT_TYPE.TYPAGE_ERREUR,
				alertTiles.periodType, alertTiles.laboratory.name,
				exportPreviousWeek
			).catch(() => enqueueSnackbar(intl.formatMessage({ id: 'global.errors.occured', defaultMessage: 'Error occured', description: 'error' }), { variant: SNACK_VARIANT.ERROR }))
		}
	}, [alertTiles, enqueueSnackbar, exportPreviousWeek, intl])

	const exportIndicatorEchantillonRetard = useCallback(() => {
		if (alertTiles) {
			getClientLaboExportFile(
				FILE_TYPE.LABO,
				LABO_CONTENT_TYPE.ECHANTILLON_RETARD,
				alertTiles.periodType, alertTiles.laboratory.name,
				exportPreviousWeek
			).catch(() => enqueueSnackbar(intl.formatMessage({ id: 'global.errors.occured', defaultMessage: 'Error occured', description: 'error' }), { variant: SNACK_VARIANT.ERROR }))
		}
	}, [alertTiles, enqueueSnackbar, exportPreviousWeek, intl])

	const exportStatus = useDisableExport(alertTiles, setExportPreviousWeek)

	return <>
		<Typography variant="h1"><FormattedMessage id="activity.byLaboratory.title"
		                                           defaultMessage="Activity by laboratory"
		                                           description="Activity by laboratory title" /></Typography>
		<FiltersContainer
			onSubmit={onSubmit}
			target={REFERENTIAL_TYPE.LABORATORY}
			selectReferentialDefaultValue={DEFAULT_VALUE_LABO}
		>
			{(!alertTiles && !graph) && <EmptyState filters={submittedFilters} />}

			{
				alertTiles && <Grid item xs={12} container sx={{ mt: '16px' }}>
					<Indicator
						smallSize
						title={<FormattedMessage id="activity.byLaboratory.indicators.totalTypes.title"
						                         defaultMessage="Total types" description="Indicator total types title" />}
						count={alertTiles.nbTypings}
						evolution={alertTiles.indicatorNbTypings}
						description={<FormattedMessage id="activity.byLaboratory.indicators.totalTypes.description"
						                               defaultMessage="Total types description"
						                               description="Indicator total types description"
						                               values={{ periodType: alertTiles.periodType, date: previousDate }} />}
						tooltip={intl.formatMessage({
							id: 'activity.byLaboratory.indicators.totalTypes.tooltip',
							defaultMessage: 'Total types tooltip',
							description: 'Indicator total types tooltip'
						})}
						updateDate={updatedDateAlertTiles}
						exportData={exportIndicatorIpvgeno}
						disableExport={exportStatus}
					/>
					<Indicator
						smallSize
						title={<FormattedMessage id="activity.byLaboratory.indicators.errorTypes.title"
						                         defaultMessage="Error types" description="Indicator error types title" />}
						count={alertTiles.nbErrors}
						evolution={alertTiles.indicatorNbErrors}
						description={<FormattedMessage id="activity.byLaboratory.indicators.errorTypes.description"
						                               defaultMessage="Error types description"
						                               description="Indicator error types description" />}
						tooltip={intl.formatMessage({
							id: 'activity.byLaboratory.indicators.errorTypes.tooltip',
							defaultMessage: 'Error types tooltip',
							description: 'Indicator error types tooltip'
						})}
						updateDate={updatedDateAlertTiles}
						exportData={exportIndicatorEchantillonErreur}
						disableExport={exportStatus}
						isRatio={true}
					/>
					<Indicator
						smallSize
						title={<FormattedMessage id="activity.byLaboratory.indicators.delayedSamples.title"
						                         defaultMessage="Delayed samples"
						                         description="Indicator delayed samples title" />}
						count={alertTiles.nbLateSamples}
						tooltip={intl.formatMessage({
							id: 'activity.byLaboratory.indicators.delayedSamples.tooltip',
							defaultMessage: 'Delayed samples tooltip',
							description: 'Indicator delayed samples tooltip'
						})}
						updateDate={updatedDateAlertTiles}
						withEvolution={false}
						disableExport={exportStatus}
						exportData={exportIndicatorEchantillonRetard}
					/>
				</Grid>
			}
			{
				graph && <Grid item xs={12} container sx={{ mt: '16px' }}>
					<DistributionByMicrochipType
						laboratory={graph.laboratory.name}
						data={graph.graphDistributionByChipType.data}
						updateDate={updatedDateAlertTiles}
					/>
					<DistributionByMicrochipVersion
						data={graph.graphDistributionByChipVersion.data}
						updateDate={updatedDateAlertTiles}
					/>
				</Grid>
			}
		</FiltersContainer>
		<ProcessButton />
	</>
}

export default ActivityByLaboratory
