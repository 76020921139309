import { DATA_TYPE } from '../../../utils/constants'
import { arePeriodEqual, getMondayOfCurrentWeek } from '../../../utils/dateUtils'
import { DateTime } from 'luxon'
import { LaboActivityAlertTiles } from '../byLaboratory/activityByLaboratory'
import { ClientActivityAlertTiles } from '../byClient/activityByClient'
import { GlobalActivityAlertTiles } from '../global/globalActivity'
import { useMemo } from 'react'

export const useDisableExport = (
	alertTiles: GlobalActivityAlertTiles | LaboActivityAlertTiles | ClientActivityAlertTiles | undefined,
	setExportPreviousWeek: (isPreviousWeek: boolean) => void
) => {
	return useMemo(() => disableExport(alertTiles, setExportPreviousWeek), [alertTiles, setExportPreviousWeek])
}

const disableExport = (
	alertTiles: GlobalActivityAlertTiles | LaboActivityAlertTiles | ClientActivityAlertTiles | undefined,
	setExportPreviousWeek: (isPreviousWeek: boolean) => void
) => {
	if (alertTiles) {
		const now = DateTime.now()
		const mondayOfCurrentWeek = getMondayOfCurrentWeek(now)
		switch (alertTiles.periodType) {
			case DATA_TYPE.WEEK:
				const isPreviousWeek = arePeriodEqual(
					alertTiles.period,
					mondayOfCurrentWeek?.minus({ week: 1 }) ?? now
				)
				setExportPreviousWeek(isPreviousWeek)
				return !(arePeriodEqual(alertTiles.period, mondayOfCurrentWeek ?? now) || isPreviousWeek)
			case DATA_TYPE.MONTH:
				setExportPreviousWeek(false)
				return !arePeriodEqual(alertTiles.period, (mondayOfCurrentWeek ?? now).startOf('month'))
			case DATA_TYPE.YEAR:
				setExportPreviousWeek(false)
				return !arePeriodEqual(alertTiles.period, (mondayOfCurrentWeek ?? now).startOf('year'))
		}
	} else {
		return false
	}
}