import React, { ReactElement, useCallback, useMemo } from 'react'
import { Grid, IconButton, Menu, MenuItem, styled, Typography } from '@mui/material'
import { colors } from '../../config/colors'
import InfoIcon from '@mui/icons-material/Info'
import TrendingUpIcon from '@mui/icons-material/TrendingUp'
import TrendingDownIcon from '@mui/icons-material/TrendingDown'
import { FormattedMessage, FormattedNumber } from 'react-intl'
import Tooltip from './Tooltip'
import MenuIcon from '@mui/icons-material/Menu'

type IndicatorProps = {
	title: ReactElement
	count: number
	withEvolution?: boolean
	evolution?: number
	description?: ReactElement
	tooltip: string
	smallSize?: boolean
	updateDate: string
	exportData?: () => void
	disableExport?: boolean
	isRatio?: boolean
}

const SEUIL = 10

const IndicatorContainer = styled(Grid)({
	padding: '20px !important',
	backgroundColor: '#FFFFFF',
	border: '1px solid #F0F0F0',
	boxShadow: '3px 4px 7px rgba(0, 0, 0, 0.09)',
	borderRadius: 8
})

const IndicatorCount = styled(Typography)({
	color: colors.indicatorNumber,
	paddingRight: 20
})

const Indicator: React.FC<IndicatorProps> = (
	{
		title,
		count,
		withEvolution = true,
		evolution,
		description,
		tooltip,
		smallSize = false,
		updateDate,
		exportData = () => {
		},
		disableExport = false,
		isRatio = false
	}
) => {
	const showDescription = useMemo(() => ((withEvolution && !!evolution) || !withEvolution) && !!description
		, [withEvolution, evolution, description])

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
	const open = (anchorEl !== null)
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)
	const handleClose = () => setAnchorEl(null)

	const renderEvolution = (evolution?: number) => {
		if (!evolution) {
			return <></>
		}
		const positiveEvolution = isRatio ? evolution < SEUIL : evolution > 0
		const color = positiveEvolution ? '#34DD50' : '#FF0000'
		const formattedEvolution = `${evolution} %`
			.replace('.', ',')
			.replace('-', '')

		const signedEvolution = evolution > 0 ? `+ ${formattedEvolution}` : `- ${formattedEvolution}`
		const showEvolution = isRatio ? formattedEvolution : signedEvolution
		const showTrendIcon = positiveEvolution ? <TrendingUpIcon /> : <TrendingDownIcon />

		return <Grid item xs={smallSize ? 12 : 4} xl={smallSize ? 12 : 3} container sx={{ color }}>
			<Typography variant="body1" sx={{ color, pr: '8px' }}>
				{showEvolution}
			</Typography>
			{!isRatio && showTrendIcon}
		</Grid>
	}

	const clickExport = useCallback(() => {
		handleClose()
		exportData()
	}, [exportData])

	return <>
		<Grid item container xs={smallSize ? 4 : 6} sx={{ pr: '16px' }}>
			<IndicatorContainer item xs={12} container>
				<Grid item xs={12} container justifyContent="space-between">
					<Grid item xs={9}><Typography variant="h2">{title}</Typography></Grid>
					<Grid item xs={3} container alignItems={'center'} justifyContent={'end'} sx={{ mt: '-10px', maxHeight: '50px' }}>
						{!disableExport && <IconButton
							aria-label="close-export-indicator"
							onClick={handleClick}
							sx={{ mr: '12px' }}
						>
							<MenuIcon />
						</IconButton>}
						<Tooltip title={tooltip} placement="top-end" arrow>
							<InfoIcon sx={{ color: colors.text }} />
						</Tooltip>
					</Grid>
				</Grid>
				<Grid item xs={12} container justifyContent="space-between" alignItems="center" sx={{ mt: '24px' }}>
					{smallSize ? <>
							<Grid item xs={12} md={3}><IndicatorCount variant="indicator1"><FormattedNumber value={count} /></IndicatorCount></Grid>
							<Grid item xs={12} md={6} xl={8} justifyContent="start" container>{renderEvolution(evolution)}</Grid>
							{showDescription && <Grid item xs={12} justifyContent="end" sx={{ mt: '16px' }}>
								<Typography variant="body1">{description}</Typography>
							</Grid>}
						</>
						:
						<Grid item container justifyContent="start" alignItems="center">
							<Grid item xs="auto">
								<IndicatorCount variant="indicator1"><FormattedNumber value={count} /></IndicatorCount>
							</Grid>
							<Grid item xs container>
								{renderEvolution(evolution)}
								{showDescription &&
									<Grid item xs={!!evolution ? 8 : 12} xl={!!evolution ? 9 : 12}><Typography variant="body1">{description}</Typography></Grid>}
							</Grid>
						</Grid>
					}
				</Grid>
				<Grid item xs={12} container sx={{ mt: '16px' }} justifyContent="end" alignItems="end">
					<Typography variant="caption">
						<FormattedMessage
							id="global.charts.updatedDate"
							defaultMessage="Updated date"
							description="Updated date"
							values={{ date: updateDate }}
						/>
					</Typography>
				</Grid>
			</IndicatorContainer>
		</Grid>
		{!disableExport && <Menu
			id="export-menu"
			anchorEl={anchorEl}
			open={open}
			onClose={handleClose}
			MenuListProps={{ 'aria-labelledby': 'basic-button' }}
		>
			<MenuItem
				onClick={clickExport}
			>
				<FormattedMessage
					id="global.charts.export.indicator.button"
					defaultMessage="Export data"
					description="export option"
				/>
			</MenuItem>
		</Menu>}
	</>
}

export default Indicator
