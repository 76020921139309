import { styled, Tooltip as MuiTooltip, tooltipClasses, TooltipProps } from '@mui/material'
import { colors } from '../../config/colors'
import React from 'react'

const Tooltip = styled(({ className, ...props }: TooltipProps) => (
	<MuiTooltip {...props} classes={{ popper: className }} />))
(() => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: '#92EDA1',
		color: colors.text,
		fontSize: 12,
		fontFamily: 'Lato',
		fontWeight: 300,
		padding: '10px 16px'
	},
	[`& .${tooltipClasses.arrow}`]: {
		color: '#92EDA1'
	}
}))

export default Tooltip
