import { styled } from '@mui/material'

export const StyledField = styled('div')<{ hasError: boolean }>(
    ({ hasError }) => ({
        backgroundColor: '#FFFFFF',
        borderRadius: '4px',
        marginBottom: hasError ? 10 : 22,
        width: '100%',
        '& .MuiInputBase-root': {
            height: '48px'
        },
        '& .MuiFormLabel-root': {
            lineHeight: 'normal'
        }
    })
)


export const StyledError = styled('span')({
    display: 'block',
    color: '#FF0000',
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 10,
    textAlign: 'initial',
    marginLeft: 12
})
