import React, { useContext } from 'react'
import { PrimaryButton } from '../../../components/button/button'
import { Autorenew } from '@mui/icons-material'
import { Grid, styled } from '@mui/material'
import { fireTaskApi } from '../services/activityApi'
import { useSnackbar } from 'notistack'
import { SNACK_VARIANT } from '../../../utils/constants'
import { useIntl } from 'react-intl'
import { AppModel } from '../../appData/appModel'
import { AppContext } from '../../appData/AppContext'

const AlignedRightContainer = styled(Grid)({
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: '16px',
    paddingTop: '16px'
})

const ProcessButton: React.FC = () => {
    const intl = useIntl()
    const { enqueueSnackbar } = useSnackbar()
    const { handleTaskInProgress } = useContext<AppModel>(AppContext)

    const fireTask: any = () => {
        return fireTaskApi()
            .then(handleTaskInProgress)
            .catch(() => enqueueSnackbar(intl.formatMessage({ id: 'global.errors.noFileFound', defaultMessage: 'No file found', description: 'error' }), { variant: SNACK_VARIANT.ERROR }))
    }

    return <AlignedRightContainer item container>
        <PrimaryButton
            label="global.buttons.process"
            onClick={fireTask}
            icon={<Autorenew />}
        />
    </AlignedRightContainer>
}

export default ProcessButton
