import React from 'react'
import { Route, Routes } from 'react-router'
import Dashboard from '../modules/dashboard/Dashboard'
import { BrowserRouter } from 'react-router-dom'
import App from '../App'
import Login from '../modules/login/Login'
import GlobalActivityPage from '../modules/activity/global/GlobalActivityPage'
import ActivityByLaboratory from '../modules/activity/byLaboratory/ActivityByLaboratoryPage'
import ActivityByClientPage from '../modules/activity/byClient/ActivityByClientPage'
import UsersPage from '../modules/users/UsersPage'
import PasswordForgotten from '../modules/login/PasswordForgotten'
import UpdatePassword from '../modules/login/UpdatePassword'
import ProfilePage from '../modules/profile/ProfilePage'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { useIntl } from 'react-intl'


const Root = () => {
	const intl = useIntl()
	return (
		<LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={intl.locale}>
			<BrowserRouter>
				<Routes>
					<Route path="/login" element={<Login />} />
					<Route path="/forgotten-password" element={<PasswordForgotten />} />
					<Route path="/init-password" element={<UpdatePassword initPassword />} />
					<Route path="/reset-password" element={<UpdatePassword />} />
					<Route path="/" element={<App />}>
						<Route path="" element={<Dashboard />} />
						<Route path="/my-profile" element={<ProfilePage />} />
						<Route path="activity/global" element={<GlobalActivityPage />} />
						<Route path="activity/laboratory" element={<ActivityByLaboratory />} />
						<Route path="activity/client" element={<ActivityByClientPage />} />
						<Route path="users" element={<UsersPage />} />
					</Route>
				</Routes>
			</BrowserRouter>
		</LocalizationProvider>
	)
}

export default Root
