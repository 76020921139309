import { BREEDS } from '../../../utils/constants'

export const formatDataIpvgenoByBreed = (data: DataIpvgenosByBreed[], intl: any) => {
	const limitIndex = new Date().getMonth() + 1
	return data
		.slice(0, limitIndex)
		.map(d => {
			return ({
				month: intl.formatMessage({
					id: `enums.month.${d.month}`,
					defaultMessage: d.month,
					description: 'X axis legend'
				}),
				...(Object.values(BREEDS).reduce((acc, curr) => ({
					...acc,
					[curr.code]: getDataIpvgenoForBreed(d.breeds, curr.code)
				}), {}))
			})
		})
}


const getDataIpvgenoForBreed = (breeds: DataBreed[], key: string) => {
	const result = breeds.find(b => b.value === key)
	return result?.nbIpvGenos || 0
}

export const formatDataIpvgenoTotal = (currentYear: number, dataYear: DataTotalIpvgeno[], dataPreviousYear: DataTotalIpvgeno[], intl: any) => {
	const limitIndex = new Date().getMonth() + 1
	const hasPreviousYearData = dataPreviousYear.length > 0
	return dataYear
		.slice(0, limitIndex)
		.map((y, index) => ({
			month: intl.formatMessage({
				id: `enums.month.${y.month}`,
				defaultMessage: y.month,
				description: 'X axis legend'
			}),
			[currentYear]: dataYear[index].nbIpvGenos,
			[currentYear - 1]: hasPreviousYearData ? dataPreviousYear[index].nbIpvGenos : 0
		}), {})
}

