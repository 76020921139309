import React, { useCallback, useContext, useMemo } from 'react'
import { Box, Collapse, Grid, IconButton, List, ListItemIcon, ListItemText, Menu, MenuItem, Paper, Toolbar, Typography, useTheme } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import logo from '../../assets/img/logo.png'
import { AppContext } from '../../modules/appData/AppContext'
import AccountCircle from '@mui/icons-material/AccountCircle'
import MenuIcon from '@mui/icons-material/Menu'
import DashboardIcon from '@mui/icons-material/Dashboard'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import AssessmentIcon from '@mui/icons-material/Assessment'
import PeopleIcon from '@mui/icons-material/People'
import { APP_ROUTES } from '../../utils/constants'
import { StyledAppBar, StyledDrawer, StyledDrawerList, StyledListItemButton } from './AppBar.styles'
import { useLocation, useNavigate } from 'react-router'
import { AppModel } from '../../modules/appData/appModel'
import { removerUserToken } from '../../utils/localStorage'
import { isAdmin } from '../../utils/formUtils'

type AppBarProps = {
	openDrawer: boolean
	handleDrawer: () => void
}

const AppBar: React.FC<AppBarProps> = ({ openDrawer, handleDrawer }) => {
	const theme = useTheme()
	const { pathname } = useLocation()
	const navigate = useNavigate()
	const { user, handleConnectedUser, setToken } = useContext<AppModel>(AppContext)
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
	const [openActivityMenu, setOpenActivityMenu] = React.useState<boolean>(false)

	const handleMenu = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget)
	const handleCloseMenu = () => setAnchorEl(null)
	const handleOpenActivityMenu = () => setOpenActivityMenu(state => !state)

	const activeMenuEntryPath = useMemo(() => Object.values(APP_ROUTES).find((path: string) => path === pathname), [pathname])
	const getMenuColor = useCallback((path: string) => path === activeMenuEntryPath ? theme.palette.primary.main : 'white', [activeMenuEntryPath, theme.palette.primary.main])
	const getMenuBackground = useCallback((path: string) => path === activeMenuEntryPath ? {
		backgroundColor: '#E8F1FD',
		borderRadius: '28px 0px 0px 28px',
		':hover': {
			backgroundColor: '#E8F1FD',
			borderRadius: '28px 0px 0px 28px'
		}
	} : {
		borderRadius: '28px 0px 0px 28px',
		':hover': {
			borderRadius: '28px 0px 0px 28px'
		}
	}, [activeMenuEntryPath])

	return <Box>
		<StyledAppBar position="fixed" color="inherit">
			<Toolbar>
				<Grid item xs={12} container justifyContent="space-between" alignItems="center">
					<Grid item xs={3} container alignItems="center">
						<Grid item>
							<IconButton
								size="large"
								edge="start"
								sx={{ color: '#133864' }}
								aria-label="menu"
								onClick={handleDrawer}
							>
								<MenuIcon />
							</IconButton>
						</Grid>
						<Grid item onClick={() => navigate(APP_ROUTES.HOME)} sx={{ cursor: 'pointer' }}>
							<img src={logo} alt="logo" height={48} width={108} />
						</Grid>
					</Grid>
					<Grid item xs={3} container alignItems="center" flexDirection="row-reverse">
						<IconButton
							size="large"
							aria-label="account of current user"
							aria-controls="menu-appbar"
							aria-haspopup="true"
							onClick={handleMenu}
							sx={{ color: '#404040' }}
						>
							{!!anchorEl ? <ExpandLessIcon /> : <ExpandMoreIcon />}
						</IconButton>
						<Typography variant="body2" sx={{ mr: '18px', fontWeight: 500 }}>{user?.firstname} {user?.lastname}</Typography>
						<AccountCircle sx={{ mr: '14px', color: '#404040' }} />
					</Grid>
				</Grid>
			</Toolbar>
		</StyledAppBar>
		<Menu
			id="menu-appbar"
			anchorEl={anchorEl}
			keepMounted
			open={Boolean(anchorEl)}
			onClose={handleCloseMenu}
			transformOrigin={{ horizontal: 'right', vertical: 'top' }}
			anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
		>
			<Paper sx={{ minWidth: '200px' }} elevation={0}>
				<MenuItem onClick={() => {
					handleCloseMenu()
					navigate(APP_ROUTES.PROFILE)
				}}>
					<Typography variant="body2">
						<FormattedMessage id="profile.myAccount" defaultMessage="Mon compte" description="account menu" />
					</Typography>
				</MenuItem>
				<MenuItem onClick={() => {
					removerUserToken()
					setToken(undefined)
					handleConnectedUser(undefined)
				}}>
					<Typography variant="body2">
						<FormattedMessage id="profile.logout" defaultMessage="Déconnexion" description="logout menu" />
					</Typography>
				</MenuItem>
			</Paper>
		</Menu>
		<StyledDrawer
			variant="persistent"
			anchor="left"
			open={openDrawer}
		>
			<Toolbar />
			<StyledDrawerList>
				<StyledListItemButton sx={getMenuBackground(APP_ROUTES.HOME)} onClick={() => navigate(APP_ROUTES.HOME)}>
					<ListItemIcon sx={{ color: getMenuColor(APP_ROUTES.HOME) }}><DashboardIcon /></ListItemIcon>
					<ListItemText>
						<Typography variant="menu" color={getMenuColor(APP_ROUTES.HOME)}>
							<FormattedMessage id="menu.dashboard" defaultMessage="Dashboard" description="Dashboard menu" />
						</Typography>
					</ListItemText>
				</StyledListItemButton>
				<StyledListItemButton onClick={handleOpenActivityMenu}>
					<ListItemIcon sx={{ color: 'white' }}><AssessmentIcon /></ListItemIcon>
					<ListItemText>
						<Typography variant="menu" color="white">
							<FormattedMessage id="menu.activity" defaultMessage="Activity" description="Activity menu" />
						</Typography>
					</ListItemText>
					{openActivityMenu ? <ExpandLessIcon sx={{ color: 'white' }} /> : <ExpandMoreIcon sx={{ color: 'white' }} />}
				</StyledListItemButton>
				<Collapse in={openActivityMenu} timeout="auto" unmountOnExit>
					<List component="div" sx={{ pl: '60px' }}>
						<StyledListItemButton sx={getMenuBackground(APP_ROUTES.GLOBAL_ACTIVITY)} onClick={() => navigate(APP_ROUTES.GLOBAL_ACTIVITY)}>
							<ListItemText>
								<Typography variant="menu" color={getMenuColor(APP_ROUTES.GLOBAL_ACTIVITY)}>
									<FormattedMessage id="menu.global" defaultMessage="Global" description="Global menu" />
								</Typography>
							</ListItemText>
						</StyledListItemButton>
						<StyledListItemButton sx={getMenuBackground(APP_ROUTES.ACTIVITY_BY_LABORATORY)} onClick={() => navigate(APP_ROUTES.ACTIVITY_BY_LABORATORY)}>
							<ListItemText>
								<Typography variant="menu" color={getMenuColor(APP_ROUTES.ACTIVITY_BY_LABORATORY)}>
									<FormattedMessage id="menu.byLaboratory" defaultMessage="By laboratory" description="By laboratory menu" />
								</Typography>
							</ListItemText>
						</StyledListItemButton>
						<StyledListItemButton sx={getMenuBackground(APP_ROUTES.ACTIVITY_BY_CLIENT)} onClick={() => navigate(APP_ROUTES.ACTIVITY_BY_CLIENT)}>
							<ListItemText>
								<Typography variant="menu" color={getMenuColor(APP_ROUTES.ACTIVITY_BY_CLIENT)}>
									<FormattedMessage id="menu.byClient" defaultMessage="By client" description="By client menu" />
								</Typography>
							</ListItemText>
						</StyledListItemButton>
					</List>
				</Collapse>
				{user && isAdmin(user) && <StyledListItemButton sx={getMenuBackground(APP_ROUTES.USERS)} onClick={() => navigate(APP_ROUTES.USERS)}>
					<ListItemIcon sx={{ color: getMenuColor(APP_ROUTES.USERS) }}><PeopleIcon /></ListItemIcon>
					<ListItemText>
						<Typography variant="menu" color={getMenuColor(APP_ROUTES.USERS)}>
							<FormattedMessage id="menu.users" defaultMessage="Utilisateurs" description="User menu" />
						</Typography>
					</ListItemText>
				</StyledListItemButton>}

			</StyledDrawerList>
		</StyledDrawer>
	</Box>
}

export default AppBar
