import React, { useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import ConfirmDialog from '../../../components/dialog/ConfirmDialog'
import { handleUserStatus } from '../services/usersApi'
import { useSnackbar } from 'notistack'
import { SNACK_VARIANT } from '../../../utils/constants'

type HandleUsersStatusProps = {
	open: boolean
	selectedRow: User
	reloadTable: () => void
	onClose: () => void
}

const HandleUsersStatus: React.FC<HandleUsersStatusProps> = ({ open, selectedRow, reloadTable, onClose }) => {
	const { enqueueSnackbar } = useSnackbar()
	const intl = useIntl()
	const [loading, setLoading] = useState<boolean>(false)
	const userDisabled = useMemo(() => selectedRow.disabled, [selectedRow])
	const handleStatus = () => {
		setLoading(true)
		handleUserStatus(selectedRow.id, !userDisabled)
			.then(() => {
				reloadTable()
				enqueueSnackbar(intl.formatMessage({ id: `users.${userDisabled ? 'enable' : 'disable'}Account.success`, defaultMessage: 'Update user status succeed', description: 'submit success' }), { variant: SNACK_VARIANT.SUCCESS })
				onClose()
			})
			.catch(() => enqueueSnackbar(intl.formatMessage({ id: 'global.errors.occured', defaultMessage: 'Error occured', description: 'error' }), { variant: SNACK_VARIANT.ERROR }))
			.finally(() => setLoading(false))
	}
	return <ConfirmDialog
		open={open}
		title={`users.${userDisabled ? 'enable' : 'disable'}Account.title`}
		description={`users.${userDisabled ? 'enable' : 'disable'}Account.description`}
		confirmLabel={`users.${userDisabled ? 'enable' : 'disable'}Account.confirm`}
		confirmLoading={loading}
		confirmClick={handleStatus}
		onClose={onClose}
	/>
}

export default HandleUsersStatus
