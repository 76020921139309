import { Paper, styled, Typography } from '@mui/material'

export const StyledSection = styled(Paper)({
	padding: 20,
	marginTop: 32
})

export const StyledSectionTitle = styled(Typography)({
	marginBottom: 32
})
