import React, { useCallback, useMemo, useState } from 'react'
import { Grid, Typography } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import Indicator from '../../../components/layout/Indicator'
import TypesBySexe from './components/TypesBySexe'
import TypesByLaboratory from './components/TypesByLaboratory'
import TypesByBreed from './components/TypesByBreed'
import { API_CALL_ACTIVITY_KEY, CLIENT_CONTENT_TYPE, DATA_TYPE, DEFAULT_VALUE_CLIENT, FILE_TYPE, REFERENTIAL_TYPE, SNACK_VARIANT } from '../../../utils/constants'
import FiltersContainer from '../../../components/form/filters/FiltersContainer'
import ProcessButton from '../components/ProcessButton'
import { FiltersFormValues } from '../../../components/form/filters/filters'
import { getActivityAlertTiles, getActivityGraphs, getClientLaboExportFile } from '../services/activityApi'
import { ClientActivityAlertTiles, ClientActivityGraphs } from './activityByClient'
import { getLastYear, getMondayOfLastWeek } from '../../../utils/dateUtils'
import EmptyState from '../../../components/layout/EmptyState'
import { useSnackbar } from 'notistack'
import { useDisableExport } from '../services/activityUtils'

const ActivityByClientPage = () => {
	const intl = useIntl()
	const { enqueueSnackbar } = useSnackbar()
	const [alertTiles, setAlertTiles] = useState<ClientActivityAlertTiles | undefined>(undefined)
	const [graph, setGraph] = useState<ClientActivityGraphs | undefined>(undefined)
	const [submittedFilters, setSubmittedFilters] = useState<FiltersFormValues | undefined>(undefined)
	const [exportPreviousWeek, setExportPreviousWeek] = useState(false)

	const onSubmit = (values: FiltersFormValues) => getClientActivityData(values.periodType, values.period, values.selectReferential)

	const getClientActivityData = (periodType: DATA_TYPE, period: string, selectReferential: string | undefined) => {
		setAlertTiles(undefined)
		setGraph(undefined)
		setSubmittedFilters({
			periodType,
			period,
			selectReferential
		})
		return Promise
			.all([
				getActivityAlertTiles(API_CALL_ACTIVITY_KEY.CLIENT, periodType, period, selectReferential),
				getActivityGraphs(API_CALL_ACTIVITY_KEY.CLIENT, periodType, period, selectReferential)
			])
			.then(result => {
				setAlertTiles(result[0])
				setGraph(result[1])
			})
	}

	const updatedDateAlertTiles = useMemo(() => alertTiles ? intl.formatDate(alertTiles.updateDate) : '', [alertTiles, intl])
	const previousDate = useMemo(() => {
		if (alertTiles) {
			if (alertTiles.periodType === DATA_TYPE.WEEK) {
				return intl.formatDate(getMondayOfLastWeek(new Date(alertTiles.period)))
			}
			return getLastYear(new Date(alertTiles.period))
		}
		return new Date()
	}, [alertTiles, intl])

	const exportIndicatorIpvgeno = useCallback(() => {
		if (alertTiles) {
			getClientLaboExportFile(
				FILE_TYPE.CLIENT,
				CLIENT_CONTENT_TYPE.IPVGENO,
				alertTiles.periodType, alertTiles.client.name,
				exportPreviousWeek
			).catch(() => enqueueSnackbar(intl.formatMessage({ id: 'global.errors.occured', defaultMessage: 'Error occured', description: 'error' }), { variant: SNACK_VARIANT.ERROR }))
		}
	}, [alertTiles, enqueueSnackbar, exportPreviousWeek, intl])

	const exportIndicatorEchantillon = useCallback(() => {
		if (alertTiles) {
			getClientLaboExportFile(
				FILE_TYPE.CLIENT,
				CLIENT_CONTENT_TYPE.ANIMAUX,
				alertTiles.periodType, alertTiles.client.name,
				exportPreviousWeek
			).catch(() => enqueueSnackbar(intl.formatMessage({ id: 'global.errors.occured', defaultMessage: 'Error occured', description: 'error' }), { variant: SNACK_VARIANT.ERROR }))
		}
	}, [alertTiles, enqueueSnackbar, exportPreviousWeek, intl])

	const exportStatus = useDisableExport(alertTiles, setExportPreviousWeek)

	return <>
		<Typography variant="h1"><FormattedMessage id="activity.byClient.title" defaultMessage="Activity by client"
		                                           description="Activity by client title" /></Typography>
		<FiltersContainer
			onSubmit={onSubmit}
			target={REFERENTIAL_TYPE.CLIENT}
			selectReferentialDefaultValue={DEFAULT_VALUE_CLIENT}
		>
			{(!alertTiles && !graph) && <EmptyState filters={submittedFilters} />}
			{
				alertTiles && <Grid item xs={12} container sx={{ mt: '16px' }}>
					<Indicator
						title={<FormattedMessage id="activity.byClient.indicators.ipvGeno.title" defaultMessage="IPV geno"
						                         description="Indicator IPV geno title" />}
						count={alertTiles.nbIpvgenos}
						evolution={alertTiles.indicatorNbIpvgenos}
						description={<FormattedMessage id="activity.byClient.indicators.ipvGeno.description"
						                               defaultMessage="IPV geno description"
						                               description="Indicator IPV geno description"
						                               values={{ periodType: alertTiles.periodType, date: previousDate }} />}
						tooltip={intl.formatMessage({
							id: 'activity.byClient.indicators.ipvGeno.tooltip',
							defaultMessage: 'IPV geno tooltip',
							description: 'Indicator IPV geno tooltip'
						})}
						updateDate={updatedDateAlertTiles}
						exportData={exportIndicatorIpvgeno}
						disableExport={exportStatus}
					/>
					<Indicator
						title={<FormattedMessage id="activity.byClient.indicators.animalErrors.title"
						                         defaultMessage="Animal errors"
						                         description="Indicator animal errors title" />}
						count={alertTiles.nbErrors}
						evolution={alertTiles.indicatorNbErrors}
						description={<FormattedMessage id="activity.byClient.indicators.animalErrors.description"
						                               defaultMessage="Animal errors description"
						                               description="Indicator animal errors description" />}
						tooltip={intl.formatMessage({
							id: 'activity.byClient.indicators.animalErrors.tooltip',
							defaultMessage: 'Animal errors tooltip',
							description: 'Indicator animal errors tooltip'
						})}
						updateDate={updatedDateAlertTiles}
						exportData={exportIndicatorEchantillon}
						disableExport={exportStatus}
						isRatio={true}
					/>
				</Grid>
			}
			{
				graph && <Grid item xs={12} container sx={{ mt: '16px' }}>
					<TypesBySexe
						data={graph.graphTypingBySex.data}
						updateDate={updatedDateAlertTiles}
					/>
					<TypesByLaboratory
						client={graph.client.name}
						data={graph.graphTypingByLab.data}
						updateDate={updatedDateAlertTiles}
					/>
					<Grid item xs={12} sx={{ mt: '16px' }}>
						<TypesByBreed
							client={graph.client.name}
							data={graph.graphTypingByBreed.data}
							updateDate={updatedDateAlertTiles}
						/>
					</Grid>
				</Grid>
			}
		</FiltersContainer>
		<ProcessButton />
	</>
}

export default ActivityByClientPage
