import { fetchFactory, download } from '../../../utils/fetch'
import { CLIENT_LABO_CONTENT_ASSOCIATION, DATA_TYPE, FILE_TYPE, GLOBAL_CONTENT_TYPE } from '../../../utils/constants'

export const getReferentialList = (type: string) => fetchFactory(`/referential/${type}`)

export const fireTaskApi = () => fetchFactory('/run-task', {
	method: 'POST',
	body: {}
})

export const getActivityAlertTiles = (key: string, periodType: DATA_TYPE, period: string, selectReferential?: string) => {
	const queryParams = new URLSearchParams({'periodType': periodType, 'period': period.substring(0, 10)})
	const path = `/${key}-activity/alert-tiles?${queryParams.toString()}`
	return fetchFactory(selectReferential ? `${path}&selectReferential=${selectReferential}` : path)
}

export const getActivityGraphs = (key: string, periodType: DATA_TYPE, period: string, selectReferential?: string) => {
	const queryParams = new URLSearchParams({'periodType': periodType, 'period': period.substring(0, 10)})
	const path =`/${key}-activity/graphs?${queryParams.toString()}`
	return fetchFactory(selectReferential ? `${path}&selectReferential=${selectReferential}` : path)
}

export const getGlobalExportFile = (content: GLOBAL_CONTENT_TYPE, periodType: DATA_TYPE, exportPreviousWeek: boolean) => {
	const queryParams = new URLSearchParams({'periodType': periodType, 'exportPreviousWeek': exportPreviousWeek ? 'true' : 'false'})
	return download(`/api/download/global/${content}?${queryParams.toString()}`)
}

export const getClientLaboExportFile = <T extends FILE_TYPE, >(fileType: T, content: CLIENT_LABO_CONTENT_ASSOCIATION[T], periodType: DATA_TYPE, searchValue: string, exportPreviousWeek: boolean) => {
	const queryParams = new URLSearchParams({
		'periodType': periodType,
		'searchValue': searchValue,
		'exportPreviousWeek': exportPreviousWeek ? 'true' : 'false'
	})
	return download(`/api/download/${fileType}/${content}?${queryParams.toString()}`)
}
