import { getUserToken, removerUserToken } from './localStorage'

const DEFAULT_ADMIN_ENDPOINT = '/api/admin'

type ApiResponse = {
	headers: any
	status: number
	ok: boolean
	text: () => {}
	json: () => {}
}

type ApiError = {
	field: string
	code?: string
	columns: string[]
	defaultMessage: string
	arguments: string[]
	rejectedValue: string
}

type TranslatedError = {
	id: string
	ids: string[]
	defaultMessage: string
	values: any
}

type TranslatedFieldError = {
	[field: string]: TranslatedError
}

/**
 * Login call
 * @param form
 */
export const login = (form: LoginForm) => {
	return fetch(`${process.env.REACT_APP_SERVER_URL}/auth/login`, {
		method: 'POST',
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({
			username: form.email,
			password: form.password
		})
	}).then(response => {
		if (response.ok) {
			return response.json()
		}
		return Promise.reject()
	})
}

/**
 * Refresh user token
 * @param token
 */
export const refresh = (token: string) =>
	fetch(`${process.env.REACT_APP_SERVER_URL}/auth/refresh`, {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json; charset=utf-8',
			Authorization: token
		}
	})
		.then(response => {
			if (response.ok) {
				return response.json()
			}
		})

/**
 * Get connected user
 * @param token
 */
export const fetchUser = (token: string) =>
	fetch(`${process.env.REACT_APP_SERVER_URL}/auth/user`, {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json; charset=utf-8',
			Authorization: token
		}
	})
		.then(response => {
			if (response.ok) {
				return response.json()
			}
		})

/**
 * Generic builder for fetch call
 * @param path
 * @param config
 * @param apiUrl
 */
export const fetchFactory = (path: string, config: any = { headers: {} }, apiUrl: string = DEFAULT_ADMIN_ENDPOINT) => {
	const {
		headers,
		...others
	} = config
	const token = getUserToken()
	const defaultHeaders: any = {
		Accept: 'application/json',
		'Content-Type': 'application/json;charset=utf-8',
		method: 'GET'
	}
	if (token) {
		defaultHeaders.Authorization = token
	}
	const newConfig = {
		headers: {
			...defaultHeaders,
			...headers
		},
		...others
	}
	return fetch(`${process.env.REACT_APP_SERVER_URL}${apiUrl}${path}`, newConfig)
		.then(handleResponse)
}

export const download = (href: string) => {
	return fetchFactory('/ticket', {
		method: 'POST',
		body: JSON.stringify({ path: href })
	}, '/api')
		.then(ticket => {
			if (href.indexOf('?') === -1) {
				window.open(`${href}?ticket=${ticket.ticket}`)
			} else {
				window.open(`${href}&ticket=${ticket.ticket}`)
			}
		})
}


/**
 * API response handler
 * @param response api response
 */
const handleResponse = (response: ApiResponse) => {
	// vérification du token
	if (response.status === 401) {
		removerUserToken()
		// TODO: Logout
	}
	if (response.status === 204) {
		return Promise.resolve()
	}
	if (!response.ok && response.status !== 400) {
		return Promise.reject(response)
	}
	// dans tous les autres cas, la réponse est traitée en JSON
	return Promise.all([response, response.json()]).then(checkErrors)
}

/**
 * Errors mapper
 * @param response
 * @param json formatted response
 */
const checkErrors = ([response, json]: [ApiResponse, any]) => {
	if (response.status === 400) {
		const globalErrors = (json.globalErrors || [])
			.map(convertError)
			.reduce((previousValue: TranslatedError[], currentValue: TranslatedError) => [...previousValue, currentValue], [])

		const fieldErrors = (json.fieldErrors || [])
			.map((error: ApiError) => ({
				[error.field]: convertError(error)
			}))
			.reduce(
				(previousValue: TranslatedFieldError, currentValue: TranslatedFieldError) => ({
					...previousValue,
					...currentValue
				}),
				{}
			)

		return Promise.reject({
			_error: globalErrors,
			...fieldErrors,
			response,
			bodyError: json
		})
	}
	return json
}

/**
 * Intl mapper for error
 * @param error error from API
 */
const convertError = (error: ApiError): TranslatedError => ({
	id: error.code || '__ERROR__',
	ids: error.columns,
	defaultMessage: error.defaultMessage,
	values: Object.assign({}, error.arguments, {
		rejectedValue: error.rejectedValue
	})
})
